import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Artists from './pages/Artists';
import Artist from './pages/Artist';
import Labels from './pages/Labels';
import Label from './pages/Label';
import Tracks from './pages/Tracks';
import Track from './pages/Track';
import Sets from './pages/Sets';
import Set from './pages/Set';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import NoSession from './pages/NoSession';
import Home from './pages/Home';
import Search from './pages/Search';
import PlayProvider from './context/Play';
import ApiProvider from './context/ApiProvider';
import UserProvider from './context/UserProvider';
import { ThemeProvider } from './context/ThemeProvider';
import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ApiProvider>
          <UserProvider>
            <PlayProvider>
              <div className="App">
                <Navbar />
                <Box
                  marginTop={12.25}
                  maxWidth={1536}
                  marginX={'auto'}
                  minHeight={'calc(100vh - 300px)'}
                  padding={{ xs: 0, sm: '0 24px' }}
                >
                  <Routes>
                    <Route
                      path={'/'}
                      element={
                        <PublicRoute>
                          <NoSession />
                        </PublicRoute>
                      }
                    >
                      <Route index element={<Navigate to={`/login`} />} />
                      <Route
                        index
                        path="login"
                        element={
                          <PublicRoute>
                            <Login />
                          </PublicRoute>
                        }
                      />
                      <Route
                        path="register"
                        element={
                          <PublicRoute>
                            <Register />
                          </PublicRoute>
                        }
                      />
                      <Route
                        path="forgot-password"
                        element={
                          <PublicRoute>
                            <ForgotPassword />
                          </PublicRoute>
                        }
                      />
                      <Route
                        path="reset"
                        element={
                          <PublicRoute>
                            <ResetPassword />
                          </PublicRoute>
                        }
                      />
                    </Route>

                    <Route
                      path="*"
                      element={
                        <PrivateRoute>
                          <Routes>
                            <Route path="/home/" index element={<Home />} />
                            <Route path="/artists/" element={<Artists />} />
                            <Route path="/artists/:id" element={<Artist />} />
                            <Route path="/labels/" element={<Labels />} />
                            <Route path="/labels/:id" element={<Label />} />
                            <Route path="/tracks/" element={<Tracks />} />
                            <Route path="/tracks/:id" element={<Track />} />
                            <Route path="/sets/" element={<Sets />} />
                            <Route path="/sets/:id" element={<Set />} />
                            <Route path="/profile/" element={<Profile />} />
                            <Route path="/search/" element={<Search />} />
                            <Route path="*" element={<Navigate to="/home" />} />
                          </Routes>
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </Box>
                <Footer />
              </div>
            </PlayProvider>
          </UserProvider>
        </ApiProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
