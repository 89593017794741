import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  PlayArrow,
  Pause,
  MoreHoriz,
  PlaylistAdd,
  Share,
  PlaylistRemove,
  Reply,
} from '@mui/icons-material';
import { useUser } from '../context/UserProvider';
import { useApi } from '../context/ApiProvider';
import { useSnackbar } from 'notistack';
import copyToClipboard from '../utils/copyToClipboard';

const ActionsMenu = ({
  showLikedTracks,
  likedTracksOpen,
  onClickLikedTracks,
  isPlaying,
  onPlayPauseClick,
  setId,
  isOnQueue,
  onRemoveQueueItem,
  set,
}) => {
  let navigate = useNavigate();
  const domain = window.location.origin;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();
  const api = useApi();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handlePlayPauseClick() {
    onPlayPauseClick();
    handleClose();
  }

  async function addToQueue() {
    const body = {
      user_id: user.id,
      set_meta_id: setId,
    };
    const response = await api.post('/queue', body);
    if (response.ok) {
      enqueueSnackbar('Set added to queue!');
    } else {
      if (response.status === 404) {
        enqueueSnackbar('This set is already in the queue.', {
          variant: 'info',
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again!', {
          variant: 'error',
        });
      }
    }
    handleClose();
  }

  function removeFromQueue() {
    onRemoveQueueItem();
    handleClose();
  }

  function goToSet() {
    navigate(`/sets/${setId}`);
    handleClose();
  }

  function shareItem() {
    const setLink = `${domain}/sets/${setId}`;
    const setMessage = `Check out this set by ${set.artist_meta?.name} on KIP: `;
    copyToClipboard(setMessage, setLink, 'Set', enqueueSnackbar);
    handleClose();
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        disableRipple
        variant="outlined"
        sx={{
          minWidth: 'unset',
          padding: '0 5px',
          height: '20px',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
        ref={buttonRef}
      >
        <MoreHoriz />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {showLikedTracks && (
          <MenuItem onClick={onClickLikedTracks}>
            <ListItemIcon>
              {likedTracksOpen ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {likedTracksOpen ? 'Hide' : 'Show'} Liked Tracks
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handlePlayPauseClick}>
          <ListItemIcon>
            {isPlaying ? (
              <Pause fontSize="small" />
            ) : (
              <PlayArrow fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>{isPlaying ? 'Pause' : 'Play'}</ListItemText>
        </MenuItem>
        {isOnQueue ? (
          <MenuItem onClick={removeFromQueue}>
            <ListItemIcon>
              <PlaylistRemove fontSize="small" />
            </ListItemIcon>
            <ListItemText>Remove from Queue</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={addToQueue}>
            <ListItemIcon>
              <PlaylistAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText>Queue</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={shareItem}>
          <ListItemIcon>
            <Share fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <MenuItem onClick={goToSet}>
          <ListItemIcon>
            <Reply fontSize="small" sx={{ transform: 'scaleX(-1)' }} />
          </ListItemIcon>
          <ListItemText>Go to Set</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

ActionsMenu.propTypes = {
  showLikedTracks: PropTypes.bool,
  likedTracksOpen: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onClickLikedTracks: PropTypes.func,
  onPlayPauseClick: PropTypes.func,
  setId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOnQueue: PropTypes.bool,
  onRemoveQueueItem: PropTypes.func,
  set: PropTypes.object,
};

export default ActionsMenu;
