import React from 'react';
import PropTypes from 'prop-types';
import HeartAni from './HeartAni';
import {
  Favorite,
  PlayArrow,
  Pause,
  FastForward,
  FastRewind,
  IosShare,
} from '@mui/icons-material';
import { styled, Stack, IconButton, Badge } from '@mui/material';

const ControlButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  svg {
    vertical-align: middle;
  }
`;

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  onLikeClick,
  onShareClick,
  loading,
  likes,
}) => (
  <Stack direction="row" justifyContent="center">
    <ControlButton
      type="button"
      aria-label="Previous"
      onClick={onPrevClick}
      disableRipple
    >
      <FastRewind />
    </ControlButton>
    <ControlButton
      type="button"
      className="play-pause"
      onClickCapture={() => onPlayPauseClick()}
      aria-label="Play-pause"
      disableRipple
    >
      {isPlaying ? <Pause fontSize="large" /> : <PlayArrow fontSize="large" />}
    </ControlButton>
    <ControlButton
      type="button"
      className="play-pause"
      onClick={() => onLikeClick()}
      aria-label="Play-pause"
      disableRipple
    >
      <Badge color="primary" badgeContent={likes} max={999}>
        {loading ? <HeartAni /> : <Favorite fontSize="large" />}
      </Badge>
    </ControlButton>
    <ControlButton
      type="button"
      className="play-pause"
      onClickCapture={() => onShareClick()}
      aria-label="Play-pause"
      disableRipple
    >
      <IosShare fontSize="large" />
    </ControlButton>
    <ControlButton
      type="button"
      aria-label="Next"
      onClick={onNextClick}
      disableRipple
    >
      <FastForward />
    </ControlButton>
  </Stack>
);

AudioControls.propTypes = {
  isPlaying: PropTypes.bool,
  onPlayPauseClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  onShareClick: PropTypes.func,
  loading: PropTypes.bool,
  likes: PropTypes.number,
};

export default AudioControls;
