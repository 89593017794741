import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Box } from '@mui/material';

const LoadMoreBtn = ({ pagination, onLoadMoreClick, loading, fullWidth }) => {
  let thereAreMore = false;
  if (pagination) {
    const { offset, count, total } = pagination;
    thereAreMore = offset + count < total;
  }

  return (
    <>
      {thereAreMore && (
        <Box
          sx={{
            m: 1,
            position: 'relative',
            width: fullWidth ? '100%' : 'unset',
          }}
        >
          <Button
            variant="outlined"
            disabled={loading}
            onClick={onLoadMoreClick}
            fullWidth={fullWidth}
          >
            Load More
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

LoadMoreBtn.propTypes = {
  pagination: PropTypes.object,
  onLoadMoreClick: PropTypes.func,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default LoadMoreBtn;
