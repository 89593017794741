import React from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';

const Kip = styled(Typography)`
  font-weight: 700;

  &::first-letter {
    font-family: Pixel;
  }
`;

const Logo = ({ size }) => {
  return (
    <Kip fontSize={size || 40} color={'primary'}>
      KIP
    </Kip>
  );
};

Logo.propTypes = {
  size: PropTypes.number,
};

export default Logo;
