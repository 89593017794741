import React, { useEffect, useState } from 'react';
import { useApi } from '../context/ApiProvider';
import PageHeader from '../components/PageHeader';
import Loading from '../components/Loading';
import TracksTable from '../components/TracksTable';
import SetsTable from '../components/SetsTable';
import ArtistsTable from '../components/ArtistsTable';
import LabelsTable from '../components/LabelsTable';
import LoadMoreBtn from '../components/LoadMoreBtn';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const Search = () => {
  const [loadingTracks, setLoadingTracks] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [tracksPagination, setTracksPagination] = useState({});
  const [loadingMoreTracks, setLoadingMoreTracks] = useState(false);
  const tracksPaginationConfig = {
    order_by: 'track_meta.title',
    order_direction: 'asc',
    search: '',
  };

  const [loadingSets, setLoadingSets] = useState(false);
  const [sets, setSets] = useState([]);
  const [setsPagination, setSetsPagination] = useState({});
  const [loadingMoreSets, setLoadingMoreSets] = useState(false);
  const setsPaginationConfig = {
    order_by: 'set_meta.set_name',
    order_direction: 'asc',
    search: '',
  };

  const [loadingArtists, setLoadingArtists] = useState(false);
  const [artists, setArtists] = useState([]);
  const [loadingMoreArtists, setLoadingMoreArtists] = useState(false);
  const [artistsPagination, setArtistsPagination] = useState({});
  const artistsPagintionConfig = {
    order_by: 'artist_meta.name',
    order_direction: 'asc',
    search: '',
  };

  const [loadingLabels, setLoadingLabels] = useState(false);
  const [labels, setLabels] = useState([]);
  const [loadingMoreLabels, setLoadingMoreLabels] = useState(false);
  const [labelsPagination, setLabelsPagination] = useState({});
  const labelsPagintionConfig = {
    order_by: 'label_meta.name',
    order_direction: 'asc',
    search: '',
  };

  const [search, setSearch] = useState('');
  const api = useApi();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('s');

  const loading =
    loadingTracks || loadingSets || loadingArtists || loadingLabels;

  useEffect(() => {
    if (searchQuery) {
      setSearch(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(search);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  const handleSearch = (search) => {
    if (search) {
      loadTracks(search);
      loadSets(search);
      loadArtists(search);
      loadLabels(search);
    } else {
      setTracks([]);
      setTracksPagination({});
      setSets([]);
      setSetsPagination({});
      setArtists([]);
      setLabels([]);
    }
  };

  const loadArtists = async (search) => {
    setLoadingArtists(true);
    const artistsConfig = {
      order_by: 'artist_meta.name',
      order_direction: 'asc',
      search: search,
    };
    const response = await api.get('/artists', artistsConfig);
    if (response.ok) {
      setArtists(response.body.data);
      setArtistsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingArtists(false);
  };

  const loadMoreArtists = async () => {
    setLoadingMoreArtists(true);
    const response = await api.get('/artists', {
      ...artistsPagintionConfig,
      search: search,
      after: artists[artists.length - 1].name,
    });
    if (response.ok) {
      const filteredArtists = removeDuplicates([
        ...artists,
        ...response.body.data,
      ]);
      setArtists(filteredArtists);
      setArtistsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingMoreArtists(false);
  };

  const loadLabels = async (search) => {
    setLoadingLabels(true);
    const artistsConfig = {
      order_by: 'label_meta.name',
      order_direction: 'asc',
      search: search,
    };
    const response = await api.get('/labels', artistsConfig);
    if (response.ok) {
      setLabels(response.body.data);
      setLabelsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingLabels(false);
  };

  const loadMoreLabels = async () => {
    setLoadingMoreLabels(true);
    const response = await api.get('/labels', {
      ...labelsPagintionConfig,
      search: search,
      after: labels[labels.length - 1].name,
    });
    if (response.ok) {
      const filteredLabels = removeDuplicates([
        ...labels,
        ...response.body.data,
      ]);
      setLabels(filteredLabels);
      setLabelsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingMoreLabels(false);
  };

  const loadTracks = async (search) => {
    setLoadingTracks(true);
    const tracksConfig = { ...tracksPaginationConfig, search: search };
    const response = await api.get('/tracks', tracksConfig);
    if (response.ok) {
      setTracks(response.body.data);
      setTracksPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingTracks(false);
  };

  const loadMoreTracks = async () => {
    setLoadingMoreTracks(true);
    const response = await api.get('/tracks', {
      ...tracksPaginationConfig,
      search: search,
      after: tracks[tracks.length - 1].title,
    });
    if (response.ok) {
      const filteredTracks = removeDuplicates([
        ...tracks,
        ...response.body.data,
      ]);
      setTracks(filteredTracks);
      setTracksPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingMoreTracks(false);
  };

  const loadSets = async (search) => {
    setLoadingSets(true);
    const setsConfig = { ...setsPaginationConfig, search: search };
    const response = await api.get('/sets', setsConfig);
    if (response.ok) {
      setSets(response.body.data);
      setSetsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingSets(false);
  };

  const loadMoreSets = async () => {
    setLoadingMoreSets(true);
    const response = await api.get('/sets', {
      ...setsPaginationConfig,
      search: search,
      after: sets[sets.length - 1].set_name,
    });
    if (response.ok) {
      const filteredSets = removeDuplicates([...sets, ...response.body.data]);
      setSets(filteredSets);
      setSetsPagination(response.body.pagination);
    } else {
      console.log(response);
    }
    setLoadingMoreSets(false);
  };

  function removeDuplicates(items) {
    return items.filter((item, index, self) => {
      const firstIndex = self.findIndex((i) => i.id === item.id);
      return index === firstIndex;
    });
  }

  return (
    <>
      <PageHeader backgroundArt={defaultArt} minHeight={350}>
        <Typography variant="h4" fontWeight="bold" mt={4}>
          <Typography variant="h4" fontWeight="bold">
            Find
          </Typography>
          <Typography
            variant="h3"
            component="p"
            color="primary"
            fontWeight="bold"
          >
            tracks, labels, artists, and sets
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            from all over the world.
          </Typography>
        </Typography>
      </PageHeader>
      <Box sx={{ padding: { xs: '20px', lg: '30px 0' }, textAlign: 'center' }}>
        <OutlinedInput
          type={'text'}
          onChange={() => setSearch(event.target.value)}
          value={search}
          sx={{ width: { xs: '100%', md: '45%' } }}
          placeholder="Search for tracks, labels, artists, and sets"
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearch('')} edge="end">
                {search ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Box my={2}>
          {artists.length > 0 && (
            <Typography
              variant="h5"
              fontWeight="bold"
              color={'white'}
              textAlign={'left'}
            >
              Artists
            </Typography>
          )}

          {loadingArtists ? (
            <Loading />
          ) : (
            <>
              <ArtistsTable items={artists} />
              <LoadMoreBtn
                pagination={artistsPagination}
                onLoadMoreClick={loadMoreArtists}
                loading={loadingMoreArtists}
              />
            </>
          )}
        </Box>
        <Box my={2}>
          {labels.length > 0 && (
            <Typography
              variant="h5"
              fontWeight="bold"
              color={'white'}
              textAlign={'left'}
            >
              Labels
            </Typography>
          )}

          {loadingLabels ? (
            <Loading />
          ) : (
            <>
              <LabelsTable items={labels} />
              <LoadMoreBtn
                pagination={labelsPagination}
                onLoadMoreClick={loadMoreLabels}
                loading={loadingMoreLabels}
              />
            </>
          )}
        </Box>
        <Box my={2}>
          {tracks.length > 0 && (
            <Typography
              variant="h5"
              fontWeight="bold"
              color={'white'}
              textAlign={'left'}
            >
              Tracks
            </Typography>
          )}
          {loadingTracks ? (
            <Loading />
          ) : (
            <>
              <TracksTable items={tracks} hideTableInteraction />
              <LoadMoreBtn
                pagination={tracksPagination}
                onLoadMoreClick={loadMoreTracks}
                loading={loadingMoreTracks}
              />
            </>
          )}
        </Box>
        <Box my={2}>
          {sets.length > 0 && (
            <Typography
              variant="h5"
              fontWeight="bold"
              color={'white'}
              textAlign={'left'}
            >
              Sets
            </Typography>
          )}

          {loadingSets ? (
            <Loading />
          ) : (
            <>
              <SetsTable items={sets} hideTableInteraction />
              <LoadMoreBtn
                pagination={setsPagination}
                onLoadMoreClick={loadMoreSets}
                loading={loadingMoreSets}
              />
            </>
          )}
        </Box>
        <Box my={10}>
          {sets.length === 0 &&
            tracks.length === 0 &&
            artists.length === 0 &&
            labels.length === 0 &&
            !loading && (
              <Typography
                variant="h5"
                fontWeight="bold"
                color={'white'}
                textAlign={'center'}
              >
                Not found
              </Typography>
            )}
        </Box>
      </Box>
    </>
  );
};

export default Search;
