import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Chip,
  Dialog,
  Autocomplete,
  TextField,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { Close, FilterList } from '@mui/icons-material';
import { useScreenSize } from '../utils/useScreenSize';
import {
  genreOptions,
  setSortOptions,
  trackSortOptions,
  artistSortOptions,
  labelsSortOptions,
} from '../constants/listFilterOptions';

const sortOptionsObj = {
  sets: setSortOptions,
  tracks: trackSortOptions,
  artists: artistSortOptions,
  labels: labelsSortOptions,
};

const sortIndexOptionsObj = {
  sets: 9,
  tracks: 9,
  artists: 3,
  labels: 3,
};

const defaultValueOptionsObj = {
  sets: 'release_date',
  tracks: 'release_date',
  artists: 'set_count',
  labels: 'set_count',
};

const ListFilter = ({ onFilter, genreQueryParam, listType }) => {
  const defaultValueIndex = sortIndexOptionsObj[listType] || 0;
  const sortOptions = sortOptionsObj[listType];
  const defaultSortValue = defaultValueOptionsObj[listType] || '';
  const { isMobile } = useScreenSize();
  const [open, setOpen] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(genreQueryParam || null);
  const [sortValue, setSortValue] = useState(sortOptions[defaultValueIndex]);
  // const [sortInputValue, setSortInputValue] = useState(
  //   sortOptions[defaultValueIndex].label
  // );

  function saveChanges() {
    setOpen(false);
    onFilter(
      selectedGenre || '',
      sortValue.value || defaultSortValue,
      sortValue.direction || 'desc'
    );
  }

  function clearFilters() {
    setOpen(false);
    setSelectedGenre(null);
    setSortValue(sortOptions[defaultValueIndex]);
    // setSortInputValue('');
    onFilter('', defaultSortValue, 'desc');
  }

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent={'flex-end'}
        marginBottom={2}
      >
        <Chip
          label={`Filter ${isMobile ? '& Sort' : ''}`}
          variant="outlined"
          onClick={() => setOpen(true)}
          icon={<FilterList />}
        />
        {!isMobile && !open && selectedGenre && (
          <Chip
            variant="Contained"
            label={selectedGenre}
            onClick={clearFilters}
            onDelete={clearFilters}
          />
        )}
        {isMobile &&
          !open &&
          (selectedGenre || sortValue.value !== defaultSortValue) && (
            <Chip
              variant="Contained"
              label={`Clear`}
              onClick={clearFilters}
              onDelete={clearFilters}
            />
          )}
      </Stack>

      <Dialog
        open={open}
        onClose={clearFilters}
        PaperProps={{ sx: { minWidth: 350, overflow: 'visible' } }}
        fullScreen={isMobile}
        sx={{ '.MuiDialog-container': { alignItems: 'flex-start' } }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Filter {isMobile ? '& Sort' : ''}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography mb={1} fontWeight={'bold'}>
            Filter by:
          </Typography>
          {!isMobile && (
            <Autocomplete
              value={selectedGenre}
              disablePortal
              id="genre-combo-box"
              options={genreOptions}
              sx={{ width: '100%', marginBottom: 4 }}
              renderInput={(params) => <TextField {...params} label="Genre" />}
              onChange={(event, value) => setSelectedGenre(value)}
            />
          )}

          {isMobile && (
            <>
              <Select
                value={selectedGenre}
                onChange={(event) => {
                  setSelectedGenre(event.target.value);
                }}
                sx={{ width: '100%', marginBottom: 4 }}
              >
                {genreOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>

              <Typography mb={1} fontWeight={'bold'}>
                Sort by:
              </Typography>
              {/* <Autocomplete
                value={sortValue}
                disablePortal
                id="sort-combo-box"
                options={sortOptions}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, newValue) => {
                  setSortValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setSortInputValue(newInputValue);
                }}
                inputValue={sortInputValue}
                sx={{ width: '100%', marginBottom: 4 }}
              /> */}
              <Select
                value={sortValue}
                onChange={(event) => {
                  setSortValue(event.target.value);
                }}
                sx={{ width: '100%', marginBottom: 4 }}
              >
                {sortOptions.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveChanges} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ListFilter.propTypes = {
  onFilter: PropTypes.func,
  genreQueryParam: PropTypes.string,
  listType: PropTypes.string,
};

export default ListFilter;
