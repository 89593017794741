import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { useApi } from '../../context/ApiProvider';
import { usePlay } from '../../context/Play';

const PlayTrackButton = ({ id }) => {
  const api = useApi();
  const { createAudioRef, playPause, audioRef, playingMetaId } = usePlay();
  const { enqueueSnackbar } = useSnackbar();

  const handlePlayTrack = async () => {
    const response = await api.get(`/set_tracks/play/${id}`);
    if (response.ok) {
      const { set_meta_id, start_time } = response.body;

      if (playingMetaId === set_meta_id) {
        audioRef.currentTime = start_time;
      } else {
        const audio = await createAudioRef(set_meta_id);
        if (audio) {
          playPause(audio, start_time);
        }
      }

      enqueueSnackbar(`Playing track!`);
    } else {
      console.log(response);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handlePlayTrack}
      startIcon={<PlayArrow />}
      sx={{
        borderLeft: '1px solid',
      }}
    >
      Play
    </Button>
  );
};

PlayTrackButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PlayTrackButton;
