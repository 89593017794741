import styled from 'styled-components';

export const PageMetaInfo = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  mix-blend-mode: normal;
  margin-bottom: 12px;

  span {
    color: #fff;
  }

  &.title {
    text-transform: uppercase;
  }
`;

export const PageMetaTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  margin: 8px 0;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const InternalPageMeta = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InternalPageArt = styled.img`
  width: 120px;
  height: 90px;
  border-radius: 6px;
`;

export const InternalPageArtLabel = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 6px;
`;

export const InternalPageText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
  color: #fff;
  margin-top: 32px;

  &.subtitle {
    margin-top: 8px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  &.title {
    margin-bottom: 8px;
  }
`;
