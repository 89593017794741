import React from 'react';
import PropTypes from 'prop-types';
import { BeatportIcon } from '../customIcons/BeatportIcon';
import { SoundCloudIcon } from '../customIcons/SoundCloudIcon';
import { SpotifyIcon } from '../customIcons/SpotifyIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Stack, Link, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const SocialLink = styled(Link)(({ theme }) => ({
  width: '50px',
  height: '50px',
  //background: theme.palette.common.black,
  color: theme.palette.common.white,
  textAlign: 'center',
  borderRadius: '4px',
  fontSize: '28px',
  border: '1px solid',

  svg: {
    marginTop: theme.spacing(1),
    verticalAlign: 'middle',
  },
}));

const SocialLinks = ({ data }) => {
  const {
    beatport,
    soundcloud,
    spotify,
    facebook,
    instagram,
    twitter,
    youtube,
    website,
  } = data;

  const hasSocial =
    beatport ||
    soundcloud ||
    spotify ||
    facebook ||
    instagram ||
    twitter ||
    youtube ||
    website;

  if (!hasSocial) return <></>;

  return (
    <Stack direction="row" spacing={2}>
      {beatport && (
        <SocialLink
          component="a"
          href={beatport}
          target="_blank"
          rel="noreferrer"
        >
          <BeatportIcon />
        </SocialLink>
      )}
      {soundcloud && (
        <SocialLink
          component="a"
          href={soundcloud}
          target="_blank"
          rel="noreferrer"
        >
          <SoundCloudIcon />
        </SocialLink>
      )}
      {spotify && (
        <SocialLink
          component="a"
          href={spotify}
          target="_blank"
          rel="noreferrer"
        >
          <SpotifyIcon />
        </SocialLink>
      )}
      {facebook && (
        <SocialLink
          component="a"
          href={facebook}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </SocialLink>
      )}
      {instagram && (
        <SocialLink
          component="a"
          href={instagram}
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </SocialLink>
      )}
      {twitter && (
        <SocialLink
          component="a"
          href={twitter}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon />
        </SocialLink>
      )}
      {youtube && (
        <SocialLink
          component="a"
          href={youtube}
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon />
        </SocialLink>
      )}
      {website && (
        <SocialLink
          component="a"
          href={`http://${website}`}
          to={{ pathname: `http://${website}` }}
          target="_blank"
          rel="noreferrer"
        >
          <LanguageIcon />
        </SocialLink>
      )}
    </Stack>
  );
};

SocialLinks.propTypes = {
  data: PropTypes.object,
};

export default SocialLinks;
