import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import copyToClipboard from '../utils/copyToClipboard';

const ShareModal = ({
  open,
  onCloseModal,
  setId,
  setMessage,
  trackId,
  trackMessage,
}) => {
  const domain = window.location.origin;
  const { enqueueSnackbar } = useSnackbar();

  function onShareTrack() {
    const trackLink = `${domain}/tracks/${trackId}`;
    copyToClipboard(trackMessage, trackLink, 'Track', enqueueSnackbar);
    onCloseModal();
  }

  function onShareSet() {
    const setLink = `${domain}/sets/${setId}`;
    copyToClipboard(setMessage, setLink, 'Set', enqueueSnackbar);
    onCloseModal();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Share the currently playing set or track?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={onShareSet}
            startIcon={<ContentCopy />}
            variant="contained"
          >
            Share Set
          </Button>
          <Button
            onClick={onShareTrack}
            startIcon={<ContentCopy />}
            variant="outlined"
          >
            Share Track
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ShareModal.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  setId: PropTypes.number,
  setMessage: PropTypes.string,
  trackId: PropTypes.number,
  trackMessage: PropTypes.string,
};

export default ShareModal;
