import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@mui/material';
import ShareButton from '../components/action_buttons/ShareButton.jsx';
import PurchaseButton from '../components/action_buttons/PurchaseButton.jsx';
import PlayTrackButton from '../components/action_buttons/PlayTrackButton.jsx';

const TrackActionButtons = ({ id, artistName, trackName, purchase_url }) => {
  return (
    <ButtonGroup size="large">
      <PlayTrackButton id={id} />
      <PurchaseButton id={id} purchase_url={purchase_url} />
      <ShareButton
        id={id}
        artistName={artistName}
        trackName={trackName}
        isTrack
      />
    </ButtonGroup>
  );
};

// Add prop-types validation
TrackActionButtons.propTypes = {
  id: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  trackName: PropTypes.string.isRequired,
  purchase_url: PropTypes.string.isRequired,
};

export default TrackActionButtons;
