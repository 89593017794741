export default async function copyToClipboard(
  message,
  link,
  linkType,
  enqueueSnackbar
) {
  try {
    const textToCopy = message + link;
    await navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar(`${linkType} link has been copied to your clipboard!`);
  } catch (error) {
    console.error('Failed to copy: ', error);
  }
}
