import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LoadMoreBtn from '../LoadMoreBtn';
import Loading from '../Loading';
import OptionsMenu from '../OptionsMenu';
import { useApi } from '../../context/ApiProvider';
import { Play } from '../../context/Play';
import { useUser } from '../../context/UserProvider';
import { formatSecondsToTime } from '../../utils/timeFormat';
import {
  Typography,
  Box,
  TextField,
  Button,
  Stack,
  Avatar,
} from '@mui/material';
import { useScreenSize } from '../../utils/useScreenSize';
import { Edit, Delete } from '@mui/icons-material';

const CommentCard = ({ comment, loading, setLoading, actionCallback }) => {
  const { isMobile } = useScreenSize();
  const [localComment, setLocalComment] = useState(comment);
  const [editingComment, setEditingComment] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedComment, setEditedComment] = useState(comment.text);
  const { user } = useUser();
  const api = useApi();

  async function editComment(e) {
    e.preventDefault();

    if (!editedComment || editingComment) {
      return;
    }

    setEditingComment(true);

    const data = {
      text: editedComment,
      set_timestamp: localComment.set_timestamp,
      set_meta_id: localComment.set_meta_id,
      track_meta_id: localComment.track_meta_id,
    };

    const response = await api.put(`/posts/${localComment.id}`, data);
    if (response.ok) {
      const data = response.body.data ? response.body.data : response.body;
      setLocalComment(data);
    } else {
      console.log(response);
    }

    setEditedComment(data.text);
    setEditingComment(false);
    setEditMode(false);
  }

  const commentOptions = [
    {
      icon: <Edit />,
      label: 'Edit',
      action: () => {
        setEditMode(true);
      },
    },
    {
      icon: <Delete />,
      label: 'Delete',
      action: async () => {
        if (loading) {
          return;
        }

        setLoading(true);
        const response = await api.delete(`/posts/${localComment.id}`);
        if (response.ok) {
          actionCallback();
        } else {
          setLoading(false);
          console.log(response);
        }
      },
    },
  ];

  const commentDate = new Date(localComment.timestamp).toLocaleDateString(
    'en-US'
  );

  const commentAt = localComment.set_timestamp
    ? formatSecondsToTime(localComment.set_timestamp)
    : '';

  return (
    <Stack
      spacing={1}
      direction={'row'}
      py={1}
      alignItems={'center'}
      sx={{ borderBottom: '1px solid #333' }}
      justifyContent={'space-between'}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
        <Avatar src={localComment.author.avatar_url} />
        <Box sx={{ color: 'white' }} width={editMode ? '100%' : 'unset'}>
          <Typography variant="body" fontWeight={'bold'} fontSize={18}>
            {localComment.author.username}{' '}
            {commentAt ? (
              <Typography variant="caption">at {commentAt}</Typography>
            ) : (
              ''
            )}
          </Typography>
          {editMode ? (
            <form onSubmit={editComment} autoComplete="off">
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={'center'}
                width="100%"
              >
                <TextField
                  label="Edit comment"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="comment"
                  onChange={(e) => setEditedComment(e.target.value)}
                  value={editedComment}
                  inputProps={{
                    autoComplete: 'off',
                    autoCapitalize: 'off',
                  }}
                  width="100%"
                />

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    height: { xs: 'unset', sm: '59px' },
                    mt: 1,
                    mb: { xs: 1, sm: 0 },
                    ml: { xs: 0, sm: 1 },
                  }}
                  disabled={editedComment === '' || editingComment}
                  fullWidth={isMobile}
                >
                  Update
                </Button>
              </Stack>
            </form>
          ) : (
            <Typography variant="body1">{localComment.text}</Typography>
          )}
          <Typography variant="caption">{commentDate}</Typography>
        </Box>
      </Stack>
      {localComment.author.id === user.id && !editMode && (
        <Box>
          <OptionsMenu options={commentOptions} />
        </Box>
      )}
    </Stack>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  actionCallback: PropTypes.func,
};

const CommentsSection = ({ commentsFrom, title, setId, trackId }) => {
  const { isMobile } = useScreenSize();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [creatingComment, setCreatingComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const paginationConfig = {
    order_by: 'timestamp',
    order_direction: 'desc',
  };
  const { playingProgress } = useContext(Play);

  const api = useApi();

  useEffect(() => {
    loadComments();
  }, []);

  async function createPost(e) {
    e.preventDefault();

    if (!comment || creatingComment) {
      return;
    }

    setCreatingComment(true);

    const data = {
      text: comment,
      set_meta_id: parseInt(setId) || null,
      track_meta_id: parseInt(trackId) || null,
      set_timestamp: playingProgress,
    };

    const response = await api.post('/posts', data);
    if (response.ok) {
      const data = response.body.data ? response.body.data : response.body;
      setComments([...[data], ...comments]);
    } else {
      console.log(response);
    }

    setComment('');
    setCreatingComment(false);
  }

  async function loadComments() {
    if (loading) {
      return;
    }

    setLoading(true);
    const response = await api.get(commentsFrom, paginationConfig);
    if (response.ok) {
      const data = response.body.data ? response.body.data : response.body;
      setComments(data);
      setPagination(response.body.pagination);
      setPage(1);
    } else {
      console.log(response);
    }
    setLoading(false);
  }

  async function loadMore() {
    setLoadingMore(true);
    const response = await api.get(commentsFrom, {
      ...paginationConfig,
      offset: 20 * page,
    });
    if (response.ok) {
      setComments([...comments, ...response.body.data]);
      setPagination(response.body.pagination);
      setPage(page + 1);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  }

  return (
    <>
      <Typography variant="h5" color={'white'}>
        {title}
      </Typography>
      {setId && (
        <Box mb={2}>
          <form onSubmit={createPost} autoComplete="off">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={'center'}
            >
              <TextField
                label="Write a comment"
                variant="outlined"
                fullWidth
                margin="normal"
                name="comment"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                inputProps={{
                  autoComplete: 'off',
                  autoCapitalize: 'off',
                }}
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{
                  height: { xs: 'unset', sm: '59px' },
                  mt: 1,
                  mb: { xs: 1, sm: 0 },
                  ml: { xs: 0, sm: 1 },
                }}
                disabled={comment === '' || creatingComment}
                fullWidth={isMobile}
              >
                Save
              </Button>
            </Stack>
          </form>
        </Box>
      )}
      {loading ? (
        <Box textAlign="center">
          <Loading alterColor />
        </Box>
      ) : (
        <Box mb={6.5} display={loading ? 'none' : 'block'}>
          <Box>
            {comments.map((comment, index) => (
              <CommentCard
                comment={comment}
                key={index}
                loading={loading}
                setLoading={setLoading}
                actionCallback={loadComments}
              />
            ))}
            {comments.length === 0 && (
              <Box my={4}>
                <Typography variant="h5" color={'white'} textAlign={'center'}>
                  It&apos;s pretty quiet here!
                </Typography>
                <Typography
                  variant="body1"
                  color={'white'}
                  textAlign={'center'}
                >
                  {setId
                    ? 'Why not be the first to share your thoughts on this set?'
                    : "Why not add comments on it's set to start the conversation?"}
                </Typography>
              </Box>
            )}
          </Box>
          <Box textAlign="center">
            <LoadMoreBtn
              pagination={pagination}
              onLoadMoreClick={loadMore}
              loading={loadingMore}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

CommentsSection.propTypes = {
  commentsFrom: PropTypes.string,
  title: PropTypes.string,
  setId: PropTypes.number,
  trackId: PropTypes.number,
};

export default CommentsSection;
