export const genreOptions = [
  'Afro House',
  'Bass / Club',
  'Breaks / Breakbeat / UK Bass',
  'Dance / Electro Pop',
  'Deep House',
  'Electro (Classic / Detroit / Modern)',
  'Electronic',
  'Electronica',
  'House',
  'Indie Dance',
  'Melodic House & Techno',
  'Minimal / Deep Tech',
  'Nu Disco / Disco',
  'Organic House / Downtempo',
  'Progressive House',
  'Tech House',
  'Techno',
  'Techno (Peak Time / Driving)',
  'Techno (Raw / Deep / Hypnotic)',
  'Trance',
];

export const setSortOptions = [
  { label: 'Artist: A-Z', value: 'artist', direction: 'asc' },
  { label: 'Artist: Z-A', value: 'artist', direction: 'desc' },
  { label: 'Title: A-Z', value: 'set_name', direction: 'asc' },
  { label: 'Title: Z-A', value: 'set_name', direction: 'desc' },
  { label: 'Curator: A-Z', value: 'sponsor', direction: 'asc' },
  { label: 'Curator: Z-A', value: 'sponsor', direction: 'desc' },
  { label: 'Genre: A-Z', value: 'genre', direction: 'asc' },
  { label: 'Genre: Z-A', value: 'genre', direction: 'desc' },
  {
    label: 'Release Date: Newest to Oldest',
    value: 'release_date',
    direction: 'asc',
  },
  {
    label: 'Release Date: Oldest to Newest',
    value: 'release_date',
    direction: 'desc',
  },
];

export const trackSortOptions = [
  { label: 'Artist: A-Z', value: 'artist', direction: 'asc' },
  { label: 'Artist: Z-A', value: 'artist', direction: 'desc' },
  { label: 'Title: A-Z', value: 'title', direction: 'asc' },
  { label: 'Title: Z-A', value: 'title', direction: 'desc' },
  { label: 'Label: A-Z', value: 'label_name', direction: 'asc' },
  { label: 'Label: Z-A', value: 'label_name', direction: 'desc' },
  { label: 'Genre: A-Z', value: 'genre', direction: 'asc' },
  { label: 'Genre: Z-A', value: 'genre', direction: 'desc' },
  {
    label: 'Release Date: Newest to Oldest',
    value: 'release_date',
    direction: 'asc',
  },
  {
    label: 'Release Date: Oldest to Newest',
    value: 'release_date',
    direction: 'desc',
  },
];

export const artistSortOptions = [
  { label: 'Name: A-Z', value: 'name', direction: 'asc' },
  { label: 'Name: Z-A', value: 'name', direction: 'desc' },
  { label: 'Sets: Asc', value: 'set_count', direction: 'asc' },
  { label: 'Sets: Desc', value: 'set_count', direction: 'desc' },
  { label: 'Tracks: Asc', value: 'track_count', direction: 'asc' },
  { label: 'Tracks: Desc', value: 'track_count', direction: 'desc' },
  { label: 'Remixes: Asc', value: 'remix_count', direction: 'asc' },
  { label: 'Remixes: Desc', value: 'remix_count', direction: 'desc' },
];

export const labelsSortOptions = [
  { label: 'Name: A-Z', value: 'name', direction: 'asc' },
  { label: 'Name: Z-A', value: 'name', direction: 'desc' },
  { label: 'Sets: Asc', value: 'set_count', direction: 'asc' },
  { label: 'Sets: Desc', value: 'set_count', direction: 'desc' },
  { label: 'Tracks: Asc', value: 'track_count', direction: 'asc' },
  { label: 'Tracks: Desc', value: 'track_count', direction: 'desc' },
  { label: 'Artists: Asc', value: 'artist_count', direction: 'asc' },
  { label: 'Artists: Desc', value: 'artist_count', direction: 'desc' },
];
