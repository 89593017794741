import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';

function PublicRoute({ children }) {
  const { user } = useUser();

  if (user === undefined) {
    return <LoadingScreen />;
  }

  if (user) {
    return <Navigate to="/home" />;
  }

  return children;
}

PublicRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PublicRoute;
