import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.div`
  min-height: ${(props) => (props.minHeight ? props.minHeight : '500')}px;
  background: linear-gradient(
      84.5deg,
      rgba(0, 0, 0, 0.7) 3.55%,
      rgba(0, 0, 0, 0.504) 98.2%
    ),
    url(${(props) => (props.art ? props.art : '')});
  background-position: center;
  background-size: cover;
  padding: 40px;
  text-align: left;
  color: #ffffff;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const PageHeader = ({ backgroundArt, children, minHeight }) => {
  return (
    <Header art={backgroundArt} minHeight={minHeight}>
      {children}
    </Header>
  );
};

PageHeader.propTypes = {
  backgroundArt: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  minHeight: PropTypes.number,
};

export default PageHeader;
