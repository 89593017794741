import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import copyToClipboard from '../../utils/copyToClipboard';
import { Button } from '@mui/material';
import { Share } from '@mui/icons-material';

const ShareButton = ({ id, artistName, isTrack, trackName }) => {
  const { enqueueSnackbar } = useSnackbar();
  const domain = window.location.origin;

  function shareItem() {
    if (isTrack) {
      copyTrack();
    } else {
      copySet();
    }
  }

  function copySet() {
    const setLink = `${domain}/sets/${id}`;
    const setMessage = `Check out this set by ${artistName} on KIP: `;
    copyToClipboard(setMessage, setLink, 'Set', enqueueSnackbar);
  }

  function copyTrack() {
    const setLink = `${domain}/tracks/${id}`;
    const message = `Check out ${trackName} by ${artistName} on KIP: `;
    copyToClipboard(message, setLink, 'Track', enqueueSnackbar);
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={shareItem}
      startIcon={<Share />}
      sx={{
        borderLeft: '1px solid',
      }}
    >
      Share
    </Button>
  );
};

ShareButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  artistName: PropTypes.string,
  trackName: PropTypes.string,
  isTrack: PropTypes.bool,
};

export default ShareButton;
