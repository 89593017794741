import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';

function PrivateRoute({ children }) {
  const { user } = useUser();
  const location = useLocation();

  if (user === undefined) {
    return <LoadingScreen />;
  }
  if (user) {
    return children;
  }

  const url = location.pathname + location.search + location.hash;
  return <Navigate to="/login" state={{ next: url }} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PrivateRoute;
