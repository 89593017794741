import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { on, off } from '../utils/events';
import { useApi } from './ApiProvider';

const UserContext = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState();
  const api = useApi();

  const clearUser = React.useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    on('logout', clearUser);
    return () => {
      off('logout', clearUser);
    };
  }, [clearUser]);

  useEffect(() => {
    (async () => {
      if (api.isAuthenticated()) {
        const response = await api.get('/me');
        setUser(response.ok ? response.body : null);
      } else {
        setUser(null);
      }
    })();
  }, []);

  const login = async (username, password) => {
    const loginIn = await api.login(username, password);
    if (loginIn === 'ok') {
      const userResponse = await api.get('/me');
      setUser(userResponse.ok ? userResponse.body : null);
      return userResponse.ok;
    }
    return loginIn;
  };

  const logout = async () => {
    await api.logout();
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UserProvider;
