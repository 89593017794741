export default function loadMp3File(mp3_permalink) {
  if (!mp3_permalink) return false;

  let mp3File = null;
  if (process.env.REACT_APP_ENV === 'dev') {
    try {
      mp3File = require(`../assets/${mp3_permalink}`);
    } catch (e) {
      console.log('Error loading mp3 file', e);
      return false;
    }
  } else {
    mp3File = `${process.env.REACT_APP_BASE_CLIENT_URL}/${mp3_permalink}`;
  }
  return mp3File;
}
