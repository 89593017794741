import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getImg } from '../utils/getImg';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Link,
  Box,
  Grid,
  IconButton,
  Collapse,
} from '@mui/material';
import { useApi } from '../context/ApiProvider';
import {
  ArrowUpward,
  ArrowDownward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import GridCard from './GridCard';
import Loading from './Loading';

const LabelItem = ({ item, asCard }) => {
  const [open, setOpen] = useState(false);
  const [loadingSets, setLoadingSets] = useState(false);
  const [sets, setSets] = useState([]);
  const [loadingTracks, setLoadingTracks] = useState(false);
  const [tracks, setTracks] = useState([]);
  const api = useApi();
  let navigate = useNavigate();

  const {
    art_url_sm,
    logo_url,
    default_img_xl,
    name,
    id,
    set_count,
    track_count,
    artist_count,
    track_genres,
  } = item;
  const labelImgSm = getImg(art_url_sm) || defaultArt;
  useEffect(() => {
    if (set_count > 0 && sets.length === 0 && open) {
      loadLabelSets();
    } else if (track_count > 0 && tracks.length === 0 && open) {
      loadLabelTracks();
    }
  }, [open]);

  async function loadLabelSets() {
    setLoadingSets(true);
    const response = await api.get(`/labels/${id}/sets`, {
      order_by: 'set_meta.release_date',
      order_direction: 'desc',
      search: '',
    });
    if (response.ok) {
      setSets(response.body.data.slice(0, 4));
    } else {
      console.log(response);
    }
    setLoadingSets(false);
  }

  async function loadLabelTracks() {
    setLoadingTracks(true);
    const response = await api.get(`/labels/${id}/tracks`, {
      order_by: 'track_meta.release_date',
      order_direction: 'desc',
      search: '',
    });
    if (response.ok) {
      setTracks(response.body.data.slice(0, 4));
    } else {
      console.log(response);
    }
    setLoadingTracks(false);
  }

  function goToLabel() {
    navigate(`/labels/${id}`);
  }

  return (
    <>
      {asCard ? (
        <GridCard
          title={name}
          art_url={default_img_xl}
          tags={track_genres || []}
          key={id}
          redirectTo={`/labels/${id}`}
        >
          {set_count > 0 && (
            <Typography variant="body2">Sets: {set_count}</Typography>
          )}
          {track_count > 0 && (
            <Typography variant="body2">Tracks: {track_count}</Typography>
          )}
          {artist_count > 0 && (
            <Typography variant="body2">Artists: {artist_count}</Typography>
          )}
        </GridCard>
      ) : (
        <>
          <TableRow
            sx={{
              '& > *': { borderBottom: 'unset !important' },
              '.MuiTableCell-root': { padding: 1 },
              ':hover': {
                backgroundColor: 'info.main',
                '& .MuiAvatar-root': {
                  borderStyle: 'solid',
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            <TableCell component="th" scope="row">
              <Avatar
                src={labelImgSm || logo_url}
                sx={{ width: 56, height: 56 }}
              />
            </TableCell>
            <TableCell align="left">
              <Link href={''} to={`/labels/${id}`} underline="hover">
                {name}
              </Link>
            </TableCell>
            <TableCell align="left">{set_count}</TableCell>
            <TableCell align="left">{track_count}</TableCell>
            <TableCell align="left">{artist_count}</TableCell>
            <TableCell align="right">
              {(set_count > 0 || track_count > 0) && (
                <IconButton
                  aria-label="expand row"
                  onClick={() => setOpen(!open)}
                  color="primary"
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {loadingSets ? (
                  <Box textAlign="center">
                    <Loading alterColor />
                  </Box>
                ) : loadingTracks ? (
                  <Box textAlign="center">
                    <Loading alterColor />
                  </Box>
                ) : sets.length > 0 ? (
                  <Box px={2} pb={2}>
                    <Typography variant="h6" marginTop={3}>
                      Sets
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} my={3}>
                      <Grid container spacing={2} columns={10}>
                        {sets.map(({ set_name, id, art_url_xl }) => (
                          <Grid item lg={2} key={id}>
                            <GridCard
                              title={set_name}
                              art_url={art_url_xl}
                              tags={[]}
                              key={id}
                              redirectTo={`/sets/${id}`}
                            />
                          </Grid>
                        ))}
                        <Grid item lg={2} textAlign="center">
                          <Box
                            sx={{
                              border: '1px solid',
                              borderColor: 'primary.main',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: 'secondary.light',
                              },
                            }}
                            onClick={goToLabel}
                          >
                            <Typography color="primary">View more</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Box px={2} pb={2}>
                    <Typography variant="h6" marginTop={3}>
                      Tracks
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} my={3}>
                      <Grid container spacing={2} columns={10}>
                        {tracks.map(({ title, id, art_url_xl }) => (
                          <Grid item lg={2} key={id}>
                            <GridCard
                              title={title}
                              art_url={art_url_xl}
                              tags={[]}
                              key={id}
                              redirectTo={`/tracks/${id}`}
                            />
                          </Grid>
                        ))}
                        <Grid item lg={2} textAlign="center">
                          <Box
                            sx={{
                              border: '1px solid',
                              borderColor: 'primary.main',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: 'secondary.light',
                              },
                            }}
                            onClick={goToLabel}
                          >
                            <Typography color="primary">View more</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

LabelItem.propTypes = {
  item: PropTypes.object,
  asCard: PropTypes.bool,
};

const LabelsTable = ({
  items,
  onTableInteraction,
  tableInteractionConfig,
  hideTableInteraction,
}) => {
  const [directionAsc, setDirectionAsc] = useState(false);
  const [activeCol, setActiveCol] = useState(
    hideTableInteraction ? '' : 'set_count'
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTableHeadClick = (selectedColumn) => {
    if (hideTableInteraction) return;

    let dir = directionAsc;
    if (selectedColumn === activeCol) {
      setDirectionAsc(!directionAsc);
      dir = !dir;
    } else {
      setDirectionAsc(true);
      dir = true;
    }
    setActiveCol(selectedColumn);
    onTableInteraction({
      ...tableInteractionConfig,
      order_by: `label_meta.${selectedColumn}`,
      order_direction: dir ? 'asc' : 'desc',
    });
  };

  if (!items.length) return <></>;

  return (
    <>
      {isMobile ? (
        <Box sx={{ flexGrow: 1, textAlign: 'left' }} my={3}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} lg={3} key={item.id}>
                <LabelItem item={item} asCard />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ overflow: 'visible' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="labels table">
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { padding: '16px 8px' },
                }}
              >
                <TableCell
                  sx={{
                    width: '5%',
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'name' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '30%',
                  }}
                  onClick={() => handleTableHeadClick('name')}
                >
                  Name
                  {activeCol === 'name' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'set_count' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '20%',
                  }}
                  onClick={() => handleTableHeadClick('set_count')}
                >
                  Sets
                  {activeCol === 'set_count' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'track_count' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '20%',
                  }}
                  onClick={() => handleTableHeadClick('track_count')}
                >
                  Tracks
                  {activeCol === 'track_count' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight:
                      activeCol === 'artist_count' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '20%',
                  }}
                  onClick={() => handleTableHeadClick('artist_count')}
                >
                  Artists
                  {activeCol === 'artist_count' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ width: '5%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <LabelItem item={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

LabelsTable.propTypes = {
  items: PropTypes.array,
  onTableInteraction: PropTypes.func,
  tableInteractionConfig: PropTypes.object,
  hideTableInteraction: PropTypes.bool,
};

export default LabelsTable;
