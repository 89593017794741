import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import ApiClient from '../ApiClient';

const ApiContext = createContext();

function ApiProvider({ children }) {
  const api = new ApiClient();
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

ApiProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ApiProvider;

export function useApi() {
  return useContext(ApiContext);
}
