import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from '../components/AudioPlayer';
import { useUser } from '../context/UserProvider';
import { useApi } from '../context/ApiProvider';
import loadMp3File from '../utils/loadMp3File';

export const Play = createContext({
  isPlaying: false,
  setIsPlaying: () => {},
  playingMetaId: null,
  setPlayingMetaId: () => {},
  playingProgress: 0,
  setPlayingProgress: () => {},
  playPause: () => {},
  audioRef: null,
  createAudioRef: () => {},
  playingSet: null,
  onSetEnded: () => {},
});

export const usePlay = () => {
  const context = useContext(Play);
  if (!context) {
    throw new Error('usePlay must be used within a PlayProvider');
  }
  return context;
};

function PlayProvider({ children }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingMetaId, setPlayingMetaId] = useState(null);
  const [playingProgress, setPlayingProgress] = useState(0);
  const [audioRef, setAudioRef] = useState(null);
  const [playingSet, setPlayingSet] = useState(null);
  const [queue, setQueue] = useState([]);

  const { user } = useUser();
  const api = useApi();

  useEffect(() => {
    if (user) loadQueue();
    if (!user) {
      setPlayingMetaId(null);
      setPlayingSet(null);
      setQueue([]);
      audioRef?.pause();
    }
  }, [user]);

  async function loadQueue() {
    const response = await api.get('/queue');
    if (response.ok) {
      const queueBody = response.body;
      setQueue(queueBody);
      const currentItem = queueBody.find((item) => item.is_current);
      const firstItem = currentItem || queueBody[0];
      if (!firstItem) return;
      setPlayingSet(firstItem.set_meta);
      setPlayingMetaId(firstItem.set_meta_id);
      return createAudioRef(firstItem.set_meta_id);
    } else {
      console.log(response);
    }
  }

  async function loadSet(setToLoad) {
    const setId = setToLoad || playingMetaId;
    if (!setId) return;
    const response = await api.get(`/sets/${setId}`);
    if (response.ok) {
      setPlayingSet(response.body);
      setPlayingMetaId(setId);
      updateQueueOnSetPlay(setId);
      return response.body;
    } else {
      console.log(response);
    }
  }

  async function createAudioRef(setToLoad) {
    if (audioRef) {
      audioRef.pause();
    }

    if (setToLoad) {
      const set = await loadSet(setToLoad);
      if (!set) return;
      const mp3File = loadMp3File(set?.mp3_permalink);
      if (mp3File) {
        const audio = new Audio(mp3File);
        setAudioRef(audio);
        return audio;
      } else {
        console.log('No mp3 file found');
      }
    }
  }

  function playPause(ref, startTime) {
    const audio = ref || audioRef;
    if (audio) {
      if (startTime) {
        audio.currentTime = startTime;
      }
      if (!audio.paused && audio.currentTime > 0 && !audio.ended) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play();
        setIsPlaying(true);
      }
    }
  }

  async function updateQueueOnSetPlay(set_id) {
    const response = await api.put(`/queue/new-current`, {
      user_id: user.id,
      is_current: true,
      set_meta_id: set_id,
    });
    if (!response.ok) {
      console.log(response);
    }
  }

  async function onSetEnded(setId) {
    const isSetOnQueue = queue.find((item) => item.set_meta_id === setId);
    if (isSetOnQueue) {
      const response = await api.put(`/queue/next`);
      if (response.ok && !isLastCurrentItem()) {
        playQueueItem();
      }
    } else {
      playQueueItem();
    }
  }

  function isLastCurrentItem() {
    const currentItem = queue.find((item) => item.is_current);
    const currentItemPos = currentItem.order - 1;
    return currentItemPos === queue.length - 1;
  }

  async function playQueueItem() {
    const ref = await loadQueue();
    if (ref) {
      playPause(ref);
    }
  }

  return (
    <Play.Provider
      value={{
        isPlaying,
        setIsPlaying,
        playingMetaId,
        setPlayingMetaId,
        playingProgress,
        setPlayingProgress,
        playPause,
        audioRef,
        createAudioRef,
        playingSet,
        onSetEnded,
      }}
    >
      <>
        {children}
        {user && playingMetaId && <AudioPlayer />}
      </>
    </Play.Provider>
  );
}

PlayProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PlayProvider;
