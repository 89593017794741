import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../context/ApiProvider';
import LoadingScreen from '../components/LoadingScreen';
import {
  Button,
  Typography,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ResetPassword = () => {
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onVerify = async (e) => {
    e.preventDefault();
    setErrors('');
    const { pass, confirmPass } = document.forms[0];

    if (pass.value.length < 3) {
      setErrors('Password must have at least 3 characters');
      return;
    }

    if (pass.value !== confirmPass.value) {
      setErrors('Passwords must match!');
      return;
    }

    const userInfo = {
      token: searchParams.get('token'),
      new_password: pass.value,
    };
    setLoading(true);
    const resetResult = await api.put('/tokens/reset', userInfo);

    if (resetResult.ok) {
      navigate('/login');
    } else {
      setErrors('Something happened, please try again');
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Box sx={{ width: '100%' }} color={'white'}>
        <Typography variant="h5" mb={1}>
          Set a new password
        </Typography>
        <Typography variant="body2" mb={3}>
          Please enter a new password, confirm it and use it later to log in.
        </Typography>

        <form onSubmit={onVerify}>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            name="pass"
            autoComplete="off"
            required
            error={errors !== ''}
          />
          <TextField
            label="Confirm password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            name="confirmPass"
            autoComplete="off"
            required
            error={errors !== ''}
          />
          {errors && (
            <Typography color="error" variant="body2">
              {errors}
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            sx={{ marginTop: 2 }}
          >
            Verify
          </Button>
        </form>
      </Box>
    </>
  );
};

export default ResetPassword;
