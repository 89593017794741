import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import IdsTable from '../IdsTable';
import LoadMoreBtn from '../LoadMoreBtn';
import { useApi } from '../../context/ApiProvider';
import { Typography, Box } from '@mui/material';

const IdsSection = ({ dataFrom, title }) => {
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState({
    order_by: 'track_meta.created_dt',
    order_direction: 'desc',
  });

  const api = useApi();

  useEffect(() => {
    loadSets();
  }, [paginationConfig, dataFrom]);

  async function loadSets() {
    setLoading(true);
    const response = await api.get(dataFrom, paginationConfig);
    if (response.ok) {
      setIds(response.body.data);
      setPagination(response.body.pagination);
      setPage(1);
    } else {
      console.log(response);
    }
    setLoading(false);
  }

  async function loadMore() {
    setLoadingMore(true);
    const response = await api.get(dataFrom, {
      ...paginationConfig,
      offset: 20 * page,
    });
    if (response.ok) {
      setIds([...ids, ...response.body.data]);
      setPagination(response.body.pagination);
      setPage(page + 1);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  }

  return (
    <>
      <Typography variant="h5" color={'white'}>
        {title}
      </Typography>
      <Box mb={6.5} textAlign="center">
        <Box mb={2}>
          {loading ? (
            <Box textAlign="center">
              <Loading alterColor />
            </Box>
          ) : (
            <IdsTable
              items={ids}
              onTableInteraction={setPaginationConfig}
              tableInteractionConfig={paginationConfig}
            />
          )}
        </Box>
        <LoadMoreBtn
          pagination={pagination}
          onLoadMoreClick={loadMore}
          loading={loadingMore}
        />
      </Box>
    </>
  );
};

IdsSection.propTypes = {
  dataFrom: PropTypes.string,
  title: PropTypes.string,
};

export default IdsSection;
