import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  MenuItem,
  Avatar,
  Link,
  Stack,
  Badge,
  Tooltip,
  Menu,
  IconButton,
} from '@mui/material';
import defaultArt from '../assets/images/dj_kip_art.png';
import { useApi } from '../context/ApiProvider';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useUser } from '../context/UserProvider';
import LoadMoreBtn from './LoadMoreBtn';
import { getImg } from '../utils/getImg';

const Notification = ({ notification, clickCallback }) => {
  const { release, is_read, read_dt, id } = notification;
  const { set_meta, track_meta, track_position } = release;
  const notificationRead = is_read || read_dt;
  const trackArt = getImg(track_meta.art_url_xl) || defaultArt;
  const dateFormatted = track_meta.release_date
    ? new Date(track_meta.release_date).toLocaleDateString('en-US')
    : '';

  if (!track_meta || !track_meta.id) return;

  return (
    <MenuItem
      onClick={() => clickCallback(id)}
      sx={{
        maxWidth: { xs: '100%', md: '450px' },
        color: 'white',
      }}
    >
      <Link
        to={`/tracks/${track_meta.id}`}
        href=""
        underline="none"
        sx={{ color: '#000' }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {trackArt ? (
            <Badge
              color="primary"
              invisible={notificationRead}
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Avatar src={trackArt} />
            </Badge>
          ) : (
            <Avatar src={defaultArt} />
          )}
          <Box>
            <Typography variant="body2" whiteSpace="normal" color={'white'}>
              ID {track_position} in {set_meta.set_name} was just released:{' '}
              <b>
                {track_meta.artist} - {track_meta.title}
              </b>
            </Typography>
            <Typography variant="body2" whiteSpace="normal" color={'white'}>
              {dateFormatted}
            </Typography>
          </Box>
        </Stack>
      </Link>
    </MenuItem>
  );
};

Notification.propTypes = {
  notification: PropTypes.object,
  clickCallback: PropTypes.func,
};

const Notifications = () => {
  const { user } = useUser();
  const [pagination, setPagination] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(0);
  const api = useApi();

  const paginationConfig = {
    order_by: 'notification.created_dt',
    order_direction: 'desc',
    search: '',
    limit: '10',
  };

  useEffect(() => {
    loadNotifications();
  }, [user]);

  const loadNotifications = async () => {
    if (user) {
      const response = await api.get('/notifications', paginationConfig);
      if (response.ok) {
        const notifications = response.body.data;
        setNotifications(notifications);
        setPagination(response.body.pagination);
        checkNewNotifications(notifications);
      } else {
        console.log(response);
      }
    }
  };

  const loadMore = async () => {
    setLoadingMore(true);
    const response = await api.get('/notifications', {
      ...paginationConfig,
      after: notifications[notifications.length - 1].created_dt,
    });
    if (response.ok) {
      const totalNotifications = [...notifications, ...response.body.data];
      setNotifications(totalNotifications);
      setPagination(response.body.pagination);
      checkNewNotifications(totalNotifications);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  };

  const checkNewNotifications = (notifications) => {
    const news = notifications.filter((notification) => !notification.is_read);
    setNewNotifications(news.length);
  };

  const handleOpenNotificationsMenu = (event) => {
    setAnchorElNoti(event.currentTarget);
  };

  const handleCloseNotificationsMenu = () => {
    setAnchorElNoti(null);
  };

  const readNotification = async (id) => {
    const response = await api.put(`/notifications/${id}`);
    if (response.ok) {
      handleCloseNotificationsMenu();
      loadNotifications();
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <Tooltip title="Notifications">
        <Box sx={{ display: 'flex', marginRight: 1 }}>
          <IconButton
            sx={{ width: '55px', height: '55px' }}
            onClick={handleOpenNotificationsMenu}
          >
            <Badge badgeContent={newNotifications} color="primary" max={99}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        sx={{
          mt: 2,
          maxHeight: { xs: '70vh', md: '50vh' },
          '.MuiMenu-list': { p: 0, backgroundColor: 'secondary.main' },
        }}
        id="menu-notifications"
        anchorEl={anchorElNoti}
        open={Boolean(anchorElNoti)}
        onClose={handleCloseNotificationsMenu}
      >
        {notifications.map((notification) => (
          <Notification
            notification={notification}
            key={notification.id}
            clickCallback={readNotification}
          />
        ))}
        {pagination.total > 10 && (
          <MenuItem sx={{ maxWidth: { xs: '100%', md: '450px' } }}>
            <LoadMoreBtn
              pagination={pagination}
              onLoadMoreClick={loadMore}
              loading={loadingMore}
              fullWidth
            />
          </MenuItem>
        )}
        {notifications.length === 0 && (
          <MenuItem sx={{ maxWidth: { xs: '100%', md: '450px' } }} disabled>
            <Typography whiteSpace="normal">No new notifications</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Notifications.propTypes = {};

export default Notifications;
