import React from 'react';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  InternalPageMeta,
  InternalPageArt,
  InternalPageText,
} from '../components/ui/page';
import SetsSection from '../components/sections/SetsSection';
import TracksSection from '../components/sections/TracksSection';
import IdsSection from '../components/sections/IdsSection';
import ArtistsSection from '../components/sections/ArtistsSection';
import LabelsSection from '../components/sections/LabelsSection';
import { useUser } from '../context/UserProvider';
import ShowMore from '../components/ShowMore';
import TabsContainer from '../components/TabsContainer';

const Profile = () => {
  const { user } = useUser();

  const { about_me, username, avatar_url, id } = user;

  return (
    <>
      <PageHeader backgroundArt={defaultArt} minHeight={350}>
        <InternalPageMeta>
          <InternalPageArt src={avatar_url || defaultArt} />
          <InternalPageText>{username}</InternalPageText>
          {about_me && <ShowMore label="Bio" content={about_me} />}
        </InternalPageMeta>
      </PageHeader>

      <TabsContainer
        tabTitles={[
          { title: 'Liked Sets' },
          { title: 'Liked Tracks' },
          { title: 'Watched IDs' },
          { title: 'Liked Artists' },
          { title: 'Liked Labels' },
        ]}
        tabContent={[
          <SetsSection
            dataFrom={`/users/${id}/sets`}
            showLikedTracks
            key={'sets'}
          />,
          <TracksSection dataFrom={`/users/${id}/tracks`} key={'tracks'} />,
          <IdsSection dataFrom={`/users/${id}/ids`} key={'ids'} />,
          <ArtistsSection dataFrom={`/users/${id}/artists`} key={'artists'} />,
          <LabelsSection dataFrom={`/users/${id}/labels`} key={'labels'} />,
        ]}
      />
    </>
  );
};

export default Profile;
