import React, { useState } from 'react';
import { useApi } from '../context/ApiProvider';
import LoadingScreen from '../components/LoadingScreen';
import {
  Button,
  Link,
  Typography,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ForgotPassword = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSend = async (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);
    const { email } = document.forms[0];
    const userInfo = {
      email: email.value,
    };
    const forgotResult = await api.post('/tokens/reset', userInfo);

    if (forgotResult.ok) {
      setMessage(
        'Please check your email inbox or SPAM folder for a password reset email from kip@kip.com.'
      );
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingScreen />}

      <Box sx={{ width: '100%' }} color={'white'}>
        <Typography variant="h5" mb={3}>
          Enter your email address. If an account exists, we will send you a
          link to reset your password.
        </Typography>
        <form onSubmit={onSend} autoComplete="off">
          <TextField
            label="Email address"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            autoComplete="off"
            type="email"
            required
          />
          {message && <Typography variant="body2">{message}</Typography>}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            sx={{ marginTop: 2 }}
          >
            Reset Password
          </Button>
          <Box mt={3}>
            <Typography mt={2} variant="body2">
              Don&apos;t have an account yet?
              <Link to="/register" href="/register" ml={1} underline="hover">
                Register
              </Link>
            </Typography>
            <Typography mt={2} variant="body2">
              Do you already have an account?
              <Link to="/login" href="/login" ml={1} underline="hover">
                Log In
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ForgotPassword;
