import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  styled,
  Chip,
  Stack,
  Box,
} from '@mui/material';
import { getImg } from '../utils/getImg';

const Tag = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),

  '&:nth-of-type(odd)': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
  },

  '&:nth-of-type(even)': {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.light,
  },
}));

const ClickableCard = styled(Card)`
  height: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const GridCard = ({ title, tags, art_url, redirectTo, children }) => {
  tags = tags.slice(0, 2);
  let navigate = useNavigate();

  function handleRedirect() {
    navigate(redirectTo);
  }

  const cardArt = getImg(art_url);

  return (
    <ClickableCard
      onClick={handleRedirect}
      sx={{
        backgroundColor: 'info.dark',
        ':hover': { backgroundColor: 'info.main' },
      }}
    >
      <CardMedia
        sx={{ height: 130, width: '100%' }}
        image={cardArt}
        title={title}
      />
      <CardContent>
        <Typography
          textAlign="left"
          gutterBottom
          variant="h3"
          fontSize="20px"
          fontWeight="bold"
        >
          {title}
        </Typography>
        {children && <Box my={1}>{children}</Box>}
        <Stack direction="row" flexWrap={'wrap'}>
          {tags &&
            tags.map((tag) => (
              <Tag key={tag} label={tag} size="small">
                {tag}
              </Tag>
            ))}
        </Stack>
      </CardContent>
    </ClickableCard>
  );
};

GridCard.propTypes = {
  title: PropTypes.string,
  art_url: PropTypes.string,
  redirectTo: PropTypes.string,
  tags: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default GridCard;
