import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from './PageHeader';
import { PageMetaInfo, PageMetaTitle } from '../components/ui/page';
import ShareButton from './action_buttons/ShareButton';
import QueueButton from './action_buttons/QueueButton';
import {
  Link,
  Button,
  Stack,
  ButtonGroup,
  IconButton,
  Badge,
  Paper,
  ListItem,
  Chip,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  PlayArrow,
  Pause,
  Star,
  StarBorder,
} from '@mui/icons-material';
import { getImg } from '../utils/getImg';
import { usePlay } from '../context/Play';
import { useApi } from '../context/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const SetMeta = ({ meta }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    id,
    set_name,
    artist_meta,
    art_url_xl,
    release_date,
    set_sponsors,
    track_count,
    track_confirmed_count,
    track_genres,
    user_fav,
  } = meta;
  const [isFav, setIsFav] = useState(user_fav?.is_fav);
  const [showLikes, setShowLikes] = useState(user_fav?.show_likes);
  const setImg = getImg(art_url_xl);
  const api = useApi();
  const { isPlaying, playingMetaId, createAudioRef, playPause, audioRef } =
    usePlay();

  useEffect(() => {
    if (set_name && artist_meta?.name) {
      const pageTitle = `${set_name} - ${artist_meta?.name}`;
      document.title = pageTitle;
      document.head
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', pageTitle);
      document.head
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', setImg);
      setIsFav(user_fav?.is_fav);
      setShowLikes(user_fav?.show_likes);
    }

    return () => {
      document.title = 'KIP';
    };
  }, [set_name]);

  const sponsor = set_sponsors ? set_sponsors[0] : null;
  const sponsorName = sponsor?.sponsor;
  const sponsorIsArtist = sponsor?.sponsor_type === 'artist_meta';
  const dateFormatted = release_date
    ? new Date(release_date).toLocaleDateString('en-US')
    : '';

  const isSetPlaying = playingMetaId === meta.id && isPlaying;

  const handlePlayPause = async () => {
    const audio = isSetPlaying ? audioRef : await createAudioRef(meta.id);
    if (audio) {
      playPause(audio);
    }
  };

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/set/${id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.is_fav, 'favorites');
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/set/${id}/likes-toggle`);
    if (response.ok) {
      setShowLikes(response.body.show_likes);
      handleLikeNotification(response.body.show_likes, 'likes');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Set added to ${type}!`);
    } else {
      enqueueSnackbar(`Set removed from ${type}!`);
    }
  }

  function handleGenreClick(genre) {
    navigate(`/sets?genre=${genre}`);
  }

  return (
    <PageHeader backgroundArt={setImg}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <PageMetaInfo className="title">
          <Link
            href=""
            to={`/artists/${artist_meta?.id}`}
            sx={{ color: '#fff', textDecorationColor: '#fff' }}
            underline="always"
          >
            {artist_meta?.name}
          </Link>
        </PageMetaInfo>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <IconButton aria-label="favorite" onClick={handleFavoriteClick}>
            {isFav ? <Star /> : <StarBorder />}
          </IconButton>
          <IconButton aria-label="favorite" onClick={handleLikeClick}>
            {showLikes ? (
              <Badge
                color="primary"
                badgeContent={user_fav?.user_likes}
                max={999}
              >
                <Favorite />
              </Badge>
            ) : (
              <FavoriteBorder />
            )}
          </IconButton>
        </Stack>
      </Stack>
      <PageMetaTitle>{set_name}</PageMetaTitle>
      <PageMetaInfo>
        Recorded on: <span>{dateFormatted}</span>
      </PageMetaInfo>
      {sponsor && (
        <PageMetaInfo>
          Curator:{' '}
          {sponsorIsArtist ? (
            <Link
              href=""
              to={`/artists/${sponsor.sponsor_id}`}
              sx={{ color: '#fff', textDecorationColor: '#fff' }}
            >
              {sponsorName}
            </Link>
          ) : (
            <Link
              href={''}
              to={`/labels/${sponsor?.sponsor_id}`}
              sx={{ color: '#fff', textDecorationColor: '#fff' }}
            >
              {sponsorName}
            </Link>
          )}
        </PageMetaInfo>
      )}
      <PageMetaInfo>
        Identified Tracks:{' '}
        <span>
          {track_confirmed_count}/{track_count}
        </span>
      </PageMetaInfo>

      {track_genres && (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'no-wrap',
            listStyle: 'none',
            p: 0,
            m: 0,
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            boxShadow: 'none',
            overflow: 'auto',
            scrollbarWidth: 'none' /* Hide scrollbar track (Firefox) */,
            msOverflowStyle: 'none' /* Hide scrollbar track (IE, Edge) */,
          }}
          component="ul"
        >
          {track_genres.slice(0, 3).map((g, i) => (
            <ListItem key={g} sx={{ width: 'unset', padding: '0 8px 8px 0' }}>
              <Chip
                color={i % 2 === 0 ? 'secondary' : 'default'}
                label={g}
                onClick={() => handleGenreClick(g)}
              />
            </ListItem>
          ))}
        </Paper>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent={{ xs: 'center', md: 'left' }}
      >
        <ButtonGroup size="large" variant="contained">
          <Button
            variant="contained"
            onClickCapture={handlePlayPause}
            startIcon={isSetPlaying ? <Pause /> : <PlayArrow />}
          >
            {isSetPlaying ? 'Pause' : 'Play'}
          </Button>
          <QueueButton setId={meta.id} />
          <ShareButton id={meta.id} artistName={artist_meta?.name} />
        </ButtonGroup>
      </Stack>
    </PageHeader>
  );
};

SetMeta.propTypes = {
  meta: PropTypes.object,
};

export default SetMeta;
