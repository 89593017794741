import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import { PlaylistAdd } from '@mui/icons-material';
import { useUser } from '../../context/UserProvider';
import { useApi } from '../../context/ApiProvider';

const QueueButton = ({ setId }) => {
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  async function addToQueue() {
    setLoading(true);
    const body = {
      user_id: user.id,
      set_meta_id: setId,
    };
    const response = await api.post('/queue', body);
    if (response.ok) {
      enqueueSnackbar('Set added to queue!');
    } else {
      if (response.status === 404) {
        enqueueSnackbar('This set is already in the queue.', {
          variant: 'info',
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again!', {
          variant: 'error',
        });
      }
    }
    setLoading(false);
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={addToQueue}
      startIcon={<PlaylistAdd />}
    >
      Queue
      {loading && (
        <CircularProgress
          size={30}
          color="secondary"
          sx={{ position: 'absolute' }}
        />
      )}
    </Button>
  );
};

QueueButton.propTypes = {
  setId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default QueueButton;
