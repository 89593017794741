import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SetMeta from '../components/SetMeta';
import TrackList from '../components/TrackList';
import LoadingScreen from '../components/LoadingScreen';
import { on, off } from '../utils/events';
import { useApi } from '../context/ApiProvider';
import CommentsSection from '../components/sections/CommentsSection';
import TabsContainer from '../components/TabsContainer';

const Set = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [set, setSet] = useState({});
  const { id } = useParams();
  const updateMetaLikes = React.useCallback(() => {
    loadSet();
  }, []);

  useEffect(() => {
    on('updateMetaLikes', updateMetaLikes);
    return () => {
      off('updateMetaLikes', updateMetaLikes);
    };
  }, [updateMetaLikes]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadSet();
      setLoading(false);
    })();
  }, [id]);

  async function loadSet() {
    const response = await api.get(`/sets/${id}`);
    if (response.ok) {
      setSet(response.body);
    } else {
      console.log(response);
    }
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <SetMeta meta={set} />

      <TabsContainer
        tabTitles={[{ title: 'Track List' }, { title: 'Comments' }]}
        tabContent={[
          <TrackList tracks={set.set_tracks} key={'tracklist'} />,
          <CommentsSection
            commentsFrom={`/sets/${id}/posts`}
            setId={id}
            key="Comments"
          />,
        ]}
      />
    </>
  );
};

export default Set;
