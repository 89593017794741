import React from 'react';
import PropTypes from 'prop-types';
import defaultArt from '../assets/images/dj_kip_art.png';
import { useNavigate } from 'react-router-dom';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Badge,
  Link,
  Box,
  Grid,
  Stack,
} from '@mui/material';
import { Visibility, VisibilityOutlined } from '@mui/icons-material';

const TrackItem = ({ item, asCard }) => {
  let navigate = useNavigate();
  const {
    art_url,
    artist,
    title,
    id,
    artist_meta,
    user_likes,
    mix_artist,
    mix_type,
    set_meta,
    genre,
  } = item;
  const dateFormatted = set_meta?.release_date
    ? new Date(set_meta.release_date).toLocaleDateString('en-US')
    : '';
  const trackTitle = `${title} ${
    mix_artist ? `(${mix_artist} ${mix_type})` : ''
  }`;

  function goToTrack() {
    navigate(`/tracks/${id}`);
  }

  return (
    <>
      {asCard ? (
        <Card sx={{ overflow: 'visible' }}>
          <CardActionArea onClick={goToTrack}>
            <CardHeader
              avatar={<Avatar src={art_url || defaultArt} />}
              action={
                <Badge color="primary" badgeContent={user_likes} max={999}>
                  {user_likes ? <Visibility /> : <VisibilityOutlined />}
                </Badge>
              }
              title={title}
              subheader={artist}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                Seen in Set: {set_meta?.set_name}
              </Typography>
              {dateFormatted && (
                <Typography variant="body2">
                  First Seen Date : {dateFormatted}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <TableRow
          sx={{
            '.MuiTableCell-root': { padding: 1 },
          }}
        >
          <TableCell component="th" scope="row">
            <Avatar
              src={art_url || defaultArt}
              sx={{ width: 56, height: 56 }}
            />
          </TableCell>
          <TableCell align="left">
            <Link
              href={''}
              to={`/artists/${artist_meta?.id}`}
              underline="hover"
            >
              {artist}
            </Link>
          </TableCell>
          <TableCell align="left">
            <Link href="" to={`/sets/${set_meta?.id}`} underline="hover">
              {set_meta?.set_name}
            </Link>
          </TableCell>
          <TableCell align="left">
            <Link
              href={''}
              to={`/tracks/${id}`}
              fontWeight="bold"
              underline="hover"
            >
              {trackTitle}
            </Link>
          </TableCell>
          <TableCell align="left">{genre}</TableCell>
          <TableCell align="left">{dateFormatted}</TableCell>
          <TableCell align="right">
            <Stack direction="row" alignItems="center">
              <Badge color="primary" badgeContent={user_likes} max={999}>
                {user_likes ? <Visibility /> : <VisibilityOutlined />}
              </Badge>
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TrackItem.propTypes = {
  item: PropTypes.object,
  asCard: PropTypes.bool,
};

const TracksTable = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!items.length) return <></>;

  return (
    <>
      {isMobile ? (
        <Box sx={{ flexGrow: 1, textAlign: 'left' }} my={3}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} lg={3} key={item.id}>
                <TrackItem item={item} asCard />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ overflow: 'visible' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { padding: '16px 8px' },
                }}
              >
                <TableCell
                  sx={{
                    width: '5%',
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '20%',
                  }}
                >
                  Artist
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '25%',
                  }}
                >
                  Seen in Set
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '15%',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '15%',
                  }}
                >
                  Genre
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '10%',
                  }}
                >
                  First seen date
                </TableCell>
                <TableCell sx={{ width: '5%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TrackItem item={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

TracksTable.propTypes = {
  items: PropTypes.array,
};

export default TracksTable;
