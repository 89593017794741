import React, { useEffect } from 'react';
import styled from 'styled-components';
import Loading from './Loading';
import useScrollBlock from '../utils/useScrollBlock';

const LoadingScreenStyled = styled.div`
  width: 100%;
  position: absolute;
  height: 150vh;
  background-color: #000000;
  opacity: 0.7;
  z-index: 9999;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 40vh;
`;

const LoadingScreen = () => {
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, []);

  return (
    <LoadingScreenStyled>
      <Loading />
    </LoadingScreenStyled>
  );
};

export default LoadingScreen;
