import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from './Logo';
import Notifications from './Notifications';
import { useUser } from '../context/UserProvider';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Menu,
  Link,
  styled,
  ButtonGroup,
  Button,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';

const Bar = styled(Toolbar)(({ theme }) => ({
  height: 90,
  borderBottom: `1px solid ${theme.palette.info.light}`,
}));

const NavBar = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { user, logout } = useUser();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [search, setSearch] = useState('');

  const goToProfile = () => {
    navigate('/profile');
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages = [
    { display: 'Sets', url: '/sets' },
    { display: 'Tracks', url: '/tracks' },
    { display: 'Artists', url: '/artists' },
    { display: 'Labels', url: '/labels' },
  ];
  const settings = [
    { display: 'Profile', action: goToProfile },
    { display: 'Logout', action: logout },
  ];

  const appBarStyles = {
    backgroundColor: 'common.black',
    backgroundImage: 'none',
    zIndex: 2,
  };

  function onSearch(event) {
    if (!search) return;
    event?.target?.blur();
    navigate(`/search?s=${search}`);
    setSearch('');
  }

  function onSearchKeyDown(event) {
    if (event.key === 'Enter' && search) {
      onSearch(event);
      event?.target?.blur();
    }
  }

  return (
    <AppBar position="fixed" sx={appBarStyles}>
      <Container maxWidth="xl" sx={{ mb: 1 }}>
        <Bar disableGutters>
          <Link
            noWrap
            href="/"
            to="/"
            sx={{
              mr: 2,
              display: 'flex',
              textDecoration: 'none',
            }}
          >
            <Logo />
          </Link>
          {user && (
            <>
              <OutlinedInput
                type={'text'}
                size="small"
                autoComplete="off"
                sx={{
                  width: { xs: '50%', sm: '70%', md: 'unset' },
                  margin: 'auto',
                  backgroundColor: 'secondary.main',
                }}
                onChange={() => setSearch(event.target.value)}
                onKeyDown={onSearchKeyDown}
                value={search}
                endAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="end" onClick={onSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <ButtonGroup
                  variant="contained"
                  color={'secondary'}
                  sx={{ marginLeft: '20%' }}
                >
                  {pages.map(({ display, url }) => (
                    <Button
                      onClick={() => navigate(url)}
                      key={url}
                      color={
                        location.pathname.includes(url)
                          ? 'primary'
                          : 'secondary'
                      }
                      sx={{ fontFamily: 'Pixel', height: 35 }}
                    >
                      {display}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>

              <Box sx={{ flexGrow: 0, display: 'flex', marginLeft: 'auto' }}>
                <Notifications />
                <Tooltip title="Open menu">
                  <Box
                    sx={{ display: 'flex', cursor: 'pointer' }}
                    onClick={handleOpenUserMenu}
                  >
                    <IconButton
                      sx={{ p: 0 }}
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width: 220,
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  {pages.map(({ display, url }) => (
                    <MenuItem
                      key={display}
                      onClick={handleCloseUserMenu}
                      sx={{ p: 0 }}
                    >
                      <Link
                        key={display}
                        to={url}
                        href={url}
                        underline="none"
                        width={'100%'}
                        px={2}
                        py={0.75}
                      >
                        {display}
                      </Link>
                    </MenuItem>
                  ))}
                  <Divider />
                  {settings.map(({ display, action }) => (
                    <MenuItem
                      key={display}
                      onClick={handleCloseUserMenu}
                      sx={{ p: 0 }}
                    >
                      <Typography
                        width={'100%'}
                        onClick={() => action()}
                        color={'primary'}
                        px={2}
                        py={0.75}
                      >
                        {display}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          )}
        </Bar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
