export function getHoursMinutesAndSeconds(totalSeconds) {
  const hoursAndMinutes = Math.floor(totalSeconds / 60);
  const seconds = Math.trunc(totalSeconds % 60);
  const hours = Math.floor(hoursAndMinutes / 60);
  const minutes = hoursAndMinutes % 60;

  return { hours, minutes, seconds };
}

function zeroPadding(number, length) {
  return String(number).padStart(length, '0');
}

export function timeFormat(seconds) {
  if (!seconds) return;
  const values = getHoursMinutesAndSeconds(seconds);
  return `${zeroPadding(values.hours, 2)}:${zeroPadding(
    values.minutes,
    2
  )}:${zeroPadding(values.seconds, 2)}`;
}

export function formatSecondsToTime(seconds) {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Pad single-digit minutes and seconds with a leading zero
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(remainingSeconds).padStart(2, '0');

  // Return formatted time string, omitting hours if zero
  if (hours > 0) {
    const paddedHours = String(hours).padStart(2, '0');
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  } else {
    return `${paddedMinutes}:${paddedSeconds}`;
  }
}
