import React, { useState, useEffect } from 'react';
import { useApi } from '../context/ApiProvider';
import PageHeader from '../components/PageHeader';
import LoadMoreBtn from '../components/LoadMoreBtn';
import LoadingScreen from '../components/LoadingScreen';
import LabelsTable from '../components/LabelsTable';
import defaultArt from '../assets/images/dj_kip_art.png';
import ListFilter from '../components/ListFilter';
import { Typography, Box } from '@mui/material';

const Labels = () => {
  const [labels, setLabels] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState({
    order_by: 'label_meta.set_count',
    order_direction: 'desc',
    search: '',
  });
  const api = useApi();

  useEffect(() => {
    loadLabels();
  }, [paginationConfig]);

  const loadLabels = async () => {
    setLoading(true);
    const response = await api.get('/labels', paginationConfig);
    if (response.ok) {
      setLabels(response.body.data);
      setPagination(response.body.pagination);
      setPage(1);
    } else {
      console.log(response);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    const response = await api.get('/labels', {
      ...paginationConfig,
      offset: 20 * page,
    });
    if (response.ok) {
      setLabels([...labels, ...response.body.data]);
      setPagination(response.body.pagination);
      setPage(page + 1);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  };

  function onFilter(genre, sortBy, sortDirection) {
    setPaginationConfig({
      ...paginationConfig,
      genre: genre,
      order_direction: sortDirection,
      order_by: `label_meta.${sortBy}`,
    });
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <PageHeader backgroundArt={defaultArt} minHeight={350}>
        <Typography variant="h3" fontWeight="bold" mt={4}>
          Discover{' '}
          <Typography
            variant="h3"
            component="span"
            color="primary"
            fontWeight="bold"
          >
            Labels
          </Typography>
        </Typography>
        <Typography mt={2}>Only independent labels & producers</Typography>
      </PageHeader>
      <Box sx={{ padding: { xs: '20px', lg: '30px 0' }, textAlign: 'center' }}>
        <Box my={2}>
          <ListFilter onFilter={onFilter} listType="labels" />
          <LabelsTable
            items={labels}
            onTableInteraction={setPaginationConfig}
            tableInteractionConfig={paginationConfig}
          />
        </Box>
        <LoadMoreBtn
          pagination={pagination}
          onLoadMoreClick={loadMore}
          loading={loadingMore}
        />
      </Box>
    </>
  );
};

export default Labels;
