import React from 'react';
import outArt from '../assets/images/out_art.png';
import Logo from './Logo';
import { Typography, Box, styled } from '@mui/material';

const BoxStyled = styled(Box)`
  width: 100%;
  height: 85vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${outArt});
  background-position: center;
  background-size: cover;
  padding: 30vh 80px 0 80px;
  color: #ffffff;
  border-radius: 20px;
`;

const Billboard = () => {
  return (
    <BoxStyled textAlign="center">
      <Logo />
      <Typography fontSize={32}>
        Discover new tracks and IDs from a global community of Independent
        Labels and Producers
      </Typography>
    </BoxStyled>
  );
};

export default Billboard;
