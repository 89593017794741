import React from 'react';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import SetsSection from '../components/sections/SetsSection';
import TracksSection from '../components/sections/TracksSection';
import ArtistsSection from '../components/sections/ArtistsSection';
import LabelsSection from '../components/sections/LabelsSection';
import { Box, Typography } from '@mui/material';
import { Favorite } from '@mui/icons-material';

const Home = () => {
  return (
    <>
      <PageHeader backgroundArt={defaultArt} minHeight={350}>
        <Typography variant="h3" fontWeight="bold" mt={6}>
          Welcome to{' '}
          <Typography
            variant="h3"
            component="p"
            color="primary"
            fontWeight="bold"
            sx={{
              display: 'inline-block',
              '&::first-letter': {
                fontFamily: 'Pixel',
              },
            }}
          >
            KIP
          </Typography>
        </Typography>
        <Typography mt={2}>
          Listen to sets and save tracks to your profile when you{' '}
          <Favorite sx={{ verticalAlign: 'bottom' }} />
        </Typography>
        <Typography mt={1}>
          Know what track is playing without Shazam
        </Typography>
        <Typography mt={1}>
          Get notifications when IDs are released by the artist
        </Typography>
      </PageHeader>

      <Box>
        <Typography></Typography>
      </Box>

      <Box sx={{ padding: { xs: '20px', lg: '30px 0px' } }}>
        <SetsSection
          dataFrom={'/featured/sets'}
          title="Featured Sets"
          hideTableInteraction
        />
        <TracksSection
          dataFrom={'/featured/tracks'}
          title="Newest Tracks"
          hideTableInteraction
        />
        <ArtistsSection
          dataFrom={'/featured/artists'}
          title="Featured Artists"
          hideTableInteraction
        />
        <LabelsSection
          dataFrom={'/featured/labels'}
          title="Featured Labels"
          hideTableInteraction
        />
      </Box>
    </>
  );
};

export default Home;
