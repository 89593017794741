import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SetsTable from '../SetsTable';
import Loading from '../Loading';
import LoadMoreBtn from '../LoadMoreBtn';
import { useApi } from '../../context/ApiProvider';
import { Typography, Box } from '@mui/material';

const SetsSection = ({
  dataFrom,
  title,
  showLikedTracks,
  hideTableInteraction,
}) => {
  const [loading, setLoading] = useState(false);
  const [sets, setSets] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState({
    order_by: 'set_meta.release_date',
    order_direction: 'desc',
  });

  const api = useApi();

  useEffect(() => {
    loadSets();
  }, [paginationConfig, dataFrom]);

  async function loadSets() {
    setLoading(true);
    const response = await api.get(dataFrom, paginationConfig);
    if (response.ok) {
      const data = response.body.data ? response.body.data : response.body;
      setSets(data);
      setPagination(response.body.pagination);
      setPage(1);
    } else {
      console.log(response);
    }
    setLoading(false);
  }

  async function loadMore() {
    setLoadingMore(true);
    const response = await api.get(dataFrom, {
      ...paginationConfig,
      offset: 20 * page,
    });
    if (response.ok) {
      setSets([...sets, ...response.body.data]);
      setPagination(response.body.pagination);
      setPage(page + 1);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  }

  return (
    <>
      {title && (
        <Typography variant="h5" color={'white'}>
          {title}
        </Typography>
      )}
      {loading && (
        <Box textAlign="center">
          <Loading alterColor />
        </Box>
      )}
      <Box mb={6.5} textAlign="center" display={loading ? 'none' : 'block'}>
        <Box mb={2}>
          <SetsTable
            items={sets}
            onTableInteraction={setPaginationConfig}
            tableInteractionConfig={paginationConfig}
            showLikedTracks={showLikedTracks}
            hideTableInteraction={hideTableInteraction}
          />
        </Box>
        <Box textAlign="center">
          <LoadMoreBtn
            pagination={pagination}
            onLoadMoreClick={loadMore}
            loading={loadingMore}
          />
        </Box>
      </Box>
    </>
  );
};

SetsSection.propTypes = {
  dataFrom: PropTypes.string,
  title: PropTypes.string,
  showLikedTracks: PropTypes.bool,
  hideTableInteraction: PropTypes.bool,
};

export default SetsSection;
