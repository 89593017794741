import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import { PageMetaInfo, PageMetaTitle } from '../components/ui/page';
import SetsSection from '../components/sections/SetsSection';
import CommentsSection from '../components/sections/CommentsSection';
import LoadingScreen from '../components/LoadingScreen';
import { useApi } from '../context/ApiProvider';
import {
  Link,
  Stack,
  IconButton,
  Badge,
  Paper,
  ListItem,
  Chip,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  Star,
  StarBorder,
} from '@mui/icons-material';
import { getImg } from '../utils/getImg';
import { useNavigate } from 'react-router-dom';
import TabsContainer from '../components/TabsContainer';
import { useSnackbar } from 'notistack';
import TrackActionButtons from '../components/TrackActionButtons';

const Track = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const [track, setTrack] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      setLoading(true);
      const response = await api.get(`/tracks/${id}`);
      if (response.ok) {
        setTrack(response.body);
      } else {
        console.log(response);
      }
      setLoading(false);
    })();
  }, [id]);

  const {
    title,
    artist,
    artist_meta,
    art_url_xl,
    release_date,
    genre,
    label_name,
    label_meta,
    mix_meta,
    mix_type,
    bpm,
    key,
    set_meta,
    purchase_url,
    user_fav,
  } = track;
  const [isFav, setIsFav] = useState(user_fav?.is_fav);
  const [showLikes, setShowLikes] = useState(user_fav?.show_likes);
  const trackImg = getImg(art_url_xl) || defaultArt;
  useEffect(() => {
    let pageTitle = 'KIP';

    if (mix_type === 'original_mix') {
      pageTitle = `${artist} - ${title}`;
    }

    if (mix_type === 'remix' || mix_type === 'edit') {
      pageTitle = `${artist} - ${title} (${mix_meta.name} Remix/Edit)`;
    }

    if (mix_type === 'ID') {
      pageTitle = `${artist} - ${title} in ${set_meta.set_name}`;
    }

    document.title = pageTitle;
    document.head
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', pageTitle);
    document.head
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', trackImg);
    setIsFav(user_fav?.is_fav);
    setShowLikes(user_fav?.show_likes);

    return () => {
      document.title = 'KIP';
    };
  }, [title, mix_type]);

  const dateFormatted = release_date
    ? new Date(release_date).toLocaleDateString('en-US')
    : '';

  const remixInfo =
    mix_type && mix_meta ? (
      <>
        (
        <Link
          to={`/artists/${mix_meta.id}`}
          sx={{ color: '#fff', textDecorationColor: '#fff' }}
        >
          {mix_meta.name}
        </Link>{' '}
        Remix)
      </>
    ) : null;

  function handleGenreClick(genre) {
    navigate(`/tracks?genre=${genre}`);
  }

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/track/${id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.is_fav, 'favorites');
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/track/${id}/likes-toggle`);
    if (response.ok) {
      setShowLikes(response.body.show_likes);
      handleLikeNotification(response.body.show_likes, 'likes');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Track added to ${type}!`);
    } else {
      enqueueSnackbar(`Track removed from ${type}!`);
    }
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <PageHeader backgroundArt={trackImg} minHeight={300}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <PageMetaInfo className="title">
            <Link
              href=""
              to={`/artists/${artist_meta?.id}`}
              sx={{ color: '#fff', textDecorationColor: '#fff' }}
              underline="always"
            >
              {artist}
            </Link>
          </PageMetaInfo>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <IconButton aria-label="favorite" onClick={handleFavoriteClick}>
              {isFav ? <Star /> : <StarBorder />}
            </IconButton>
            <IconButton aria-label="favorite" onClick={handleLikeClick}>
              {showLikes ? (
                <Badge
                  color="primary"
                  badgeContent={user_fav?.user_likes}
                  max={999}
                >
                  <Favorite />
                </Badge>
              ) : (
                <FavoriteBorder />
              )}
            </IconButton>
          </Stack>
        </Stack>

        <PageMetaTitle>
          {title} {remixInfo}
        </PageMetaTitle>
        <PageMetaInfo>
          Label{' '}
          <span>
            <Link
              href=""
              to={`/labels/${label_meta?.id}`}
              sx={{ color: '#fff', textDecorationColor: '#fff' }}
              underline="always"
            >
              {label_name}
            </Link>
          </span>
        </PageMetaInfo>
        <PageMetaInfo>
          Released Date: <span>{dateFormatted}</span>
        </PageMetaInfo>
        <PageMetaInfo>
          BPM: <span>{bpm}</span>
        </PageMetaInfo>
        <PageMetaInfo>
          Key: <span>{key}</span>
        </PageMetaInfo>
        {genre && (
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'no-wrap',
              listStyle: 'none',
              p: 0,
              m: 0,
              backgroundColor: 'transparent',
              backgroundImage: 'none',
              boxShadow: 'none',
              overflow: 'auto',
              scrollbarWidth: 'none' /* Hide scrollbar track (Firefox) */,
              msOverflowStyle: 'none' /* Hide scrollbar track (IE, Edge) */,
            }}
            component="ul"
          >
            <ListItem sx={{ width: 'unset', padding: '0 8px 8px 0' }}>
              <Chip
                color={'secondary'}
                label={genre}
                onClick={() => handleGenreClick(genre)}
              />
            </ListItem>
          </Paper>
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent={{ xs: 'center', md: 'left' }}
        >
          <TrackActionButtons
            id={id}
            artistName={artist_meta?.name}
            trackName={title}
            purchase_url={purchase_url}
          />
        </Stack>
      </PageHeader>

      <TabsContainer
        tabTitles={[{ title: 'Sets' }, { title: 'Comments' }]}
        tabContent={[
          <SetsSection dataFrom={`/tracks/${id}/sets`} key="Sets" />,
          <CommentsSection
            commentsFrom={`/tracks/${id}/posts`}
            trackId={id}
            setId={null}
            key="Comments"
          />,
        ]}
      />
    </>
  );
};

export default Track;
