import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LoadingAni = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 20px auto;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &.alter {
      border-color: #deff7b;
    }
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;

const Loading = ({ alterColor, onButton }) => {
  return (
    <LoadingAni className={onButton && 'on-button'}>
      <div className={alterColor && 'alter'}></div>
      <div className={alterColor && 'alter'}></div>
    </LoadingAni>
  );
};

Loading.propTypes = {
  alterColor: PropTypes.bool,
  onButton: PropTypes.bool,
};

export default Loading;
