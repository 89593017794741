import { Favorite } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const Animation = styled.div`
  svg {
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @keyframes lds-heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;

const HeartAni = () => {
  return (
    <Animation>
      <Favorite fontSize="large" />
    </Animation>
  );
};

export default HeartAni;
