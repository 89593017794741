import defaultArt from '../assets/images/dj_kip_art.png';

export function getImg(imgUrl) {
  if (!imgUrl) return;

  let img = defaultArt;
  if (process.env.REACT_APP_ENV === 'dev') {
    try {
      img = require(`../assets/${imgUrl}`);
    } catch (e) {
      try {
        // make sure you have the default image file in your project
        img = require(`../assets/downloads/img/defaultArt.jpeg`);
      } catch (e) {
        console.log('Error loading image', e);
      }
    }
  } else {
    img = `${process.env.REACT_APP_BASE_CLIENT_URL}/${imgUrl}`;
  }
  return img;
}
