import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

const OptionsMenu = ({ options }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionClick = (option) => {
    option.action();
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        disableRipple
        variant="outlined"
        sx={{
          minWidth: 'unset',
          padding: '0 5px',
          height: '20px',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
        ref={buttonRef}
      >
        <MoreHoriz />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option, index) => (
          <MenuItem onClick={() => handleOptionClick(option)} key={index}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

OptionsMenu.propTypes = {
  options: PropTypes.array,
};

export default OptionsMenu;
