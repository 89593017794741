import React from 'react';
import Link from '@mui/material/Link';
import Logo from './Logo';
import styled from 'styled-components';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Typography, Grid } from '@mui/material';
import { SoundCloudIcon } from '../customIcons/SoundCloudIcon';
import { SpotifyIcon } from '../customIcons/SpotifyIcon';
import { useUser } from '../context/UserProvider';

const FooterStyled = styled.footer`
  text-align: center;
  padding: 50px 0;
  position: relative;
  margin-bottom: 70px;
`;

const Container = styled.div`
  width: 50%;
  margin: auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Icons = styled.div`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-bottom: 40px;

  .social-redirect {
    margin: 5px 16px;
  }

  .icons-divider {
    height: 30px;
    margin: 3px 0 3px 16px;
    border-right: 0;
    border: 1px solid #deff7b;
  }
`;

const Links = styled.div`
  display: flex;
  margin: 40px auto;
  justify-content: space-between;
`;

const Footer = () => {
  const { user } = useUser();
  const year = new Date().getFullYear();

  return (
    <>
      <FooterStyled>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Icons>
                <Link to="/" href="/" underline="none">
                  <Logo size={28} className={'logo2'} />
                </Link>
                <div className="icons-divider"></div>
                <a
                  className="social-redirect"
                  href="https://www.instagram.com/djkipmusic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon color="primary" />
                </a>
                <a
                  className="social-redirect"
                  href="https://www.twitter.com/djkipmusic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon color="primary" />
                </a>
                <a
                  className="social-redirect"
                  href="https://www.soundcloud.com/djkipmusic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SoundCloudIcon color="primary" />
                </a>
                <a
                  className="social-redirect"
                  href="https://www.spotify.com/djkipmusic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpotifyIcon color="primary" />
                </a>
              </Icons>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color={'white'}>
                KIP democratizes access to electronic music producers, enabling
                new talent to be discovered, and advocating the enjoyment of
                musical journeys for all.
              </Typography>
            </Grid>
            {user && (
              <Grid item xs={12}>
                <Links>
                  <Link href="/" to="/" underline="hover">
                    Home
                  </Link>
                  <Link href="/artists" to="/artists" underline="hover">
                    Artists
                  </Link>
                  <Link href="/tracks" to="/tracks" underline="hover">
                    Tracks
                  </Link>
                  <Link href="/sets" to="/sets" underline="hover">
                    Sets
                  </Link>
                  <Link href="/labels" to="/labels" underline="hover">
                    Labels
                  </Link>
                </Links>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">
                © {year} KIP | All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </FooterStyled>
    </>
  );
};

export default Footer;
