import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.displayName = 'LinkBehavior';

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: 'Helvetica, Pixel, Arial, sans-serif',
  },
  palette: {
    mode: 'dark',
    common: {
      white: '#fff',
      black: '#000',
    },
    primary: {
      main: '#DEFF7B',
      light: 'rgb(228, 255, 149)',
      dark: 'rgb(155, 178, 86)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#1a1a1e',
      light: 'rgb(71, 71, 75)',
      dark: 'rgb(18, 18, 21)',
      contrastText: '#fff',
    },
    info: {
      main: '#333337',
      light: 'rgb(91, 91, 95)',
      dark: 'rgb(35, 35, 38)',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: 500,
          textTransform: 'unset',
          height: '50px',
          minWidth: { xs: 'unset', md: '120px' },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: '100% !important',
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
