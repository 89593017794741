import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Slider, IconButton } from '@mui/material';
import { VolumeUp, VolumeOff } from '@mui/icons-material';

const VolumeControl = ({ trackVolume, setTrackVolume }) => {
  const [muted, setMuted] = useState(false);

  const handleMuteClick = () => {
    setMuted(!muted);
    const volume = muted ? 1 : 0;
    setTrackVolume(volume);
  };

  const onScrub = (value) => {
    if (value === '0') {
      setMuted(true);
    } else {
      setMuted(false);
    }
    setTrackVolume(value);
  };

  return (
    <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
      <IconButton onClick={handleMuteClick}>
        {muted ? <VolumeOff /> : <VolumeUp />}
      </IconButton>
      <Slider
        aria-label="Volume"
        value={trackVolume}
        onChange={(e) => onScrub(e.target.value)}
        step={0.1}
        min={0}
        max={1}
      />
    </Stack>
  );
};

VolumeControl.propTypes = {
  trackVolume: PropTypes.number,
  setTrackVolume: PropTypes.func,
};

export default VolumeControl;
