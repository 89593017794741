import React from 'react';
import { Outlet } from 'react-router-dom';
import Billboard from '../components/Billboard';
import { Grid } from '@mui/material';

const NoSession = () => {
  return (
    <Grid container spacing={2} pt={3}>
      <Grid item sm={12} md={6}>
        <Grid container padding={10} sx={{ padding: { xs: 2, lg: 10 } }}>
          <Outlet />
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        p={2}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Billboard />
      </Grid>
    </Grid>
  );
};

export default NoSession;
