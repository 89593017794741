import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Collapse, styled, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  return <IconButton {...props} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ShowMore = ({ label, content }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Typography paragraph>
        {label}{' '}
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>
      <Collapse in={expanded}>
        <Typography textAlign={'justify'} marginBottom={2}>
          {content}
        </Typography>
      </Collapse>
    </>
  );
};

ShowMore.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
};

export default ShowMore;
